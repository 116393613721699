import React from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components';
import { Select } from 'antd';

const NavigateButtons:React.FC = ()=>{

  const location = useLocation();
  
  return (
  <div style={{
    display:"flex",
    justifyContent: 'space-around'
  }}>
    <Link style={{padding:'12px 18px', marginLeft:'128px', textDecoration:'none'}} to="/">
      {location.pathname === '/' ? <LinkButtonSelected>Home</LinkButtonSelected> : <LinkButton>Home</LinkButton>}
    </Link>
    <Link style={{padding:'12px 18px', textDecoration:'none'}} to="/pool">
      {location.pathname === '/pool' ? <LinkButtonSelected>Market</LinkButtonSelected> : <LinkButton>Market</LinkButton>}
    </Link>

    {/* <Link style={{padding:'12px 18px', textDecoration:'none'}} to="/borrow"><LinkButton>Borrow</LinkButton></Link> */}
    <Link style={{padding:'12px 18px', marginRight:'128px', textDecoration:'none'}} to="/cdp">
      {location.pathname === '/cdp' ? <LinkButtonSelected>CDP</LinkButtonSelected> : <LinkButton>CDP</LinkButton>} 
    </Link>
  </div>);
}

const LinkButton = styled.button`
  background-color: #FFF;
  border-radius: 8px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  box-shadow: rgba(37, 39, 61, 0.15) 0px 1px 6px;
  color: #596F78;
  width: 5rem;
  height: 2rem;
  :hover{
    background-color: rgb(240, 240, 240);
  }
`

const LinkButtonSelected = styled.button`
  background-color: #575CFE;
  border-radius: 8px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  width: 5rem;
  height: 2rem;
  box-shadow: rgba(37, 39, 61, 0.15) 0px 1px 6px;
  color: #FFF;
`

export default NavigateButtons;