import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Loading3QuartersOutlined
} from '@ant-design/icons'
import { Spin } from 'antd'

import { useSelector } from 'react-redux'

import { useRdt } from '../hooks/useRdt'
import { PoolData } from '../component/Types'

import CONFIG from '../env/index'
import {
  queryAssetStates,
  genPoolDataByAssetSate,
} from '../utils/ContractDataUtil'
import PoolCard from '../component/PoolCard'
import NavigateButtons from '../component/NavigateButtons'

interface Props {
  device: String
}

enum PoolListStatus {
  INIT,
  READY,
}

const Pool: React.FC<Props> = (props) => {
  const wallet = useSelector((state: any) => state.Wallet)
  const priceMap = useSelector((state: any) => state.Common.price_map)
  const [status, setStatus] = useState<PoolListStatus>(0)
  const [poolDataArray, setPoolDataArray] = useState<PoolData[]>([])
  const rdt = useRdt()

  useEffect(() => {
    setStatus(0)
    setPoolDataArray([])
    initData()
  }, [])

  useEffect(() => {
    initData()
  }, [priceMap])

  const freshPrice = () => {
    if (poolDataArray.length > 0 && priceMap) {
      poolDataArray.forEach((item) => {
        item.price = priceMap.get(item.symbol)
        item.priceInXrd = priceMap.get(item.symbol + '_in_XRD')
      })
    }
  }

  const initData = async () => {
    if (!priceMap) {
      return
    }
    if (priceMap && poolDataArray.length > 0) {
      freshPrice()
    } else {
      queryAssetStates(rdt, handleAssetStates);
    }
  }

  const handleAssetStates = async (assetStates:any) => {
    var taskWatcher = assetStates.length
    let dataArray: PoolData[] = []

    assetStates.forEach((assetState:any) => {
      const poolDataResult = genPoolDataByAssetSate(
        rdt,
        assetState,
        priceMap
      )
      poolDataResult.then((poolData) => {
        if (poolData !== undefined) {
          dataArray?.push(poolData)
          taskWatcher--
          dataArray.sort((a, b) => a.sort - b.sort)
        }
        if (taskWatcher === 0) {
          setPoolDataArray(dataArray)
          setStatus(1)
        }
      })
    })
    
  }

  const getPoolCardList = () => {
    if (status === PoolListStatus.INIT) {
      return (
        <div style={{ textAlign: 'center', marginTop: '128px' }}>
          <Spin
            indicator={
              <Loading3QuartersOutlined
                style={{ fontSize: '37px', color: '#787A9B' }}
                spin
              />
            }
          ></Spin>
        </div>
      )
    } else {
      const poolCardList = poolDataArray.map((item, index) => (
        <PoolCard
          device={props.device}
          data={item}
          key={index}
          handleFaucet={handleFaucet}
        ></PoolCard>
      ))
      return <DataZone device={props.device}>{poolCardList}</DataZone>
    }
  }

  const handleFaucet = async (address: string) => {
    let componentAddress = ''
    if (address === CONFIG.USDT_ADDRESS) {
      componentAddress = CONFIG.COMPONENT_USDT_ADDRESS
    } else if (address === CONFIG.USDC_ADDRESS) {
      componentAddress = CONFIG.COMPONENT_USDC_ADDRESS
    } else {
      return
    }
    const manifest = `
    CALL_METHOD
      Address("${componentAddress}")
      "free_token"
      255u8;
    CALL_METHOD
      Address("${wallet.walletDataState.accounts[0].address}")
     "deposit_batch"
     Expression("ENTIRE_WORKTOP");
    `
    await rdt?.walletApi.sendTransaction({
      transactionManifest: manifest,
    })
  }

  return (
    <Layout>
      <LayoutContainer>
        <NavigateButtons />
        {getPoolCardList()}
      </LayoutContainer>
    </Layout>
  )
}

// Layout
const Layout = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  display: flex;
`

const LayoutContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  position: relative;
`

const DataZone = styled.div`
  gap: ${(props: { device: String }) =>
    props.device === 'mobile' ? '24px' : '48px'};
  grid-template-columns: ${(props: { device: String }) =>
    props.device === 'mobile' ? '2f4' : '2fr 2fr'};
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  align-items: flex-start;
`

export default Pool
