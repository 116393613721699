import React from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components';
import { Select } from 'antd';

interface Props{
  device: String
}

const SwitchButtons:React.FC<Props> = (props)=>{
  const location = useLocation();
  const navigate = useNavigate();

  const onSelect=(value:String)=>{
    navigate(''+value);
  }

  return(
    <>
    {props.device === 'mobile'? 
      <SwitchSelectWrapper>
        <Select style={{width:'100%'}} bordered={false} defaultValue={location.pathname} onSelect={onSelect}>
          <Select.Option value="/supply">Supply</Select.Option>
          <Select.Option value="/withdraw">Withdraw</Select.Option>
          <Select.Option value="/borrow">Borrow</Select.Option>
          <Select.Option value="/repay">Repay</Select.Option>
        </Select>
      </SwitchSelectWrapper>
      :
      <SwitchZone>
        <SwitchContainer>
          <Link to='/supply' style={{ textDecoration:'none'}}>
            {location.pathname === '/supply' ? <SwitchButton1>Supply</SwitchButton1> : <SwitchButton2>Supply</SwitchButton2>}
          </Link>
          <Link to='/withdraw' style={{ textDecoration:'none'}}>
            {location.pathname === '/withdraw' ? <SwitchButton1>Withdraw</SwitchButton1> : <SwitchButton2>Withdraw</SwitchButton2>}
          </Link>
          <Link to='/borrow' style={{ textDecoration:'none'}}>
            {location.pathname === '/borrow' ? <SwitchButton1>Borrow</SwitchButton1> : <SwitchButton2>Borrow</SwitchButton2>}
          </Link>
          <Link to='/repay' style={{ textDecoration:'none'}}>
            {location.pathname === '/repay' ? <SwitchButton1>Repay</SwitchButton1> : <SwitchButton2>Repay</SwitchButton2>}
          </Link>
        </SwitchContainer>
      </SwitchZone>
    }
    </>
  );
}

// switch zone
const SwitchZone = styled.div`
  display:flex;
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 1;
`
const SwitchContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  border-radius: 58px;
  background-color: #F1F3F4;
  display: flex;
`
const SwitchButton1 = styled.button`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  appearance: none;
  text-align: center;
  text-decoration: none;
  border: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-family: Inter, "Helvetica Neue", sans-serif;
  font-weight: 600;
  line-height: 1.5;
  cursor: pointer;
  transition: all 150ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  font-size: 16px;
  padding: 16px 32px;
  border-radius: 58px;
  background-color: #25273D;
  color: #FFFFFF;
  box-shadow: rgba(37, 39, 61, 0.15) 0px 1px 6px;
  :hover {
    background-color: #787A9B;
  }
`
const SwitchButton2 = styled.button`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  appearance: none;
  text-align: center;
  text-decoration: none;
  border: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-family: Inter, "Helvetica Neue", sans-serif;
  font-weight: 600;
  line-height: 1.5;
  background-color: transparent;
  color: #787A9B;
  cursor: pointer;
  transition: all 150ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  font-size: 16px;
  padding: 16px 32px;
  border-radius: 58px;
  :hover {
    color: #25273D
  }
`
const SwitchSelectWrapper = styled.div`
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  outline: 0px !important;
`

export default SwitchButtons;