import { put, takeEvery } from "redux-saga/effects";
import { WalletActionType } from "../reducers/Wallet";
import { WalletDataState } from "@radixdlt/radix-dapp-toolkit";

export function* doHandleWalletData(walletDataState:WalletDataState){
  
}

export function* watchWallet(){
  // yield takeEvery(WalletActionType.TEST, doTest);
}