import { WalletDataState } from "@radixdlt/radix-dapp-toolkit";

const initState = {
    status: 0,
    assetStates: [],
    poolCardDatas: [],
}


export enum PoolActionType {
    UPDATE_STATUS = "POOL.UPDATE_STATUS",
    UPDATE_ASSET_STATES = "POOL.UPDATE_ASSET_STATES",
    UPDATE_POOL_CARD_DATAS = "POOL.UPDATE_POOL_CARD_DATAS",
}

const Pool = (state=initState, action: {type: string, payload:any}) => {
    switch (action.type) {
        case PoolActionType.UPDATE_STATUS:
          return { ...state, status: action.payload }
        case PoolActionType.UPDATE_ASSET_STATES:
          return { ...state, assetStates: action.payload }
        case PoolActionType.UPDATE_POOL_CARD_DATAS:
          return { ...state, poolCardDatas: action.payload }
        default :
         return state;
    }
}

export default Pool;