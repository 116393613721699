import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useRdt } from '../hooks/useRdt'
import { useSelector } from 'react-redux'
import ReactPlayer from 'react-player'

interface Props {
  device: String
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'radix-connect-button': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

const Home: React.FC<Props> = (props) => {
  const walletData = useSelector((state: any) => state.Wallet)

  return (
    <Layout>
      <LayoutContainer>
        <Intro>
          <IntroTitle>Dexian Lending Protocol</IntroTitle>
          <IntroContent>
            DeXian Protocol, bringing greater liquidity and more efficient
            trading experience to Radix.
          </IntroContent>
          <ReactPlayer
            url="https://youtu.be/Z8Gd0VwsAiI"
            controls={true}
            width={props.device === 'mobile' ? '320px' : '640px'}
            height={props.device === 'mobile' ? '180px' : '360px'}
            style={{ marginBottom: '12px' }}
          />
          {walletData.connected ? (
            <Link to={{ pathname: '/pool' }} style={{ textDecoration: 'none' }}>
              <StartButton>
                See products !
                <ErrowSvg
                  viewBox="0 0 20 20"
                  display="inline-block"
                  focusable="false"
                  role="presentation"
                >
                  <path
                    d="M19.4908 10.0863L19.5969 10.1924L19.4908 10.2984L18.8347 10.9546L18.8338 10.9555L12.9248 16.8644C12.5036 17.2856 11.8207 17.2856 11.3995 16.8644C10.9783 16.4432 10.9783 15.7603 11.3995 15.3391L15.4677 11.2709L2.31319 11.2709C1.71752 11.2709 1.23462 10.7881 1.23462 10.1924C1.23462 9.5967 1.71752 9.11381 2.31319 9.11381L15.4677 9.11381L11.3995 5.04565C10.9783 4.62444 10.9783 3.94153 11.3995 3.52032C11.8207 3.09911 12.5036 3.09911 12.9248 3.52032L18.8338 9.42926L18.8347 9.43017L19.4908 10.0863Z"
                    strokeWidth="0.3"
                    strokeLinecap="round"
                    fill="currentColor"
                  ></path>
                </ErrowSvg>
              </StartButton>
            </Link>
          ) : (
            <radix-connect-button />
          )}
        </Intro>
      </LayoutContainer>
    </Layout>
  )
}

// Layout
const Layout = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  display: flex;
`
const LayoutContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  position: relative;
`

// intro
const Intro = styled.div`
  box-sizing: border-box;
  margin: 80px 0px 24px;
  min-width: 0px;
  position: relative;
  justify-self: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  display: flex;
`
const IntroTitle = styled.div`
  box-sizing: border-box;
  margin: 0px 0px 16px;
  min-width: 0px;
  font-family: __FTPolarMedium_6973ac, __FTPolarMedium_Fallback_6973ac,
    'Helvetica Neue', sans-serif;
  font-weight: 500;
  line-height: 54px;
  font-size: 52px;
  letter-spacing: -0.02em;
  color: #25273d;
`
const IntroContent = styled.div`
  box-sizing: border-box;
  margin: 0px 0px 32px;
  min-width: 0px;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  line-height: 28px;
  font-size: 18px;
  color: #787a9b;
  max-width: 740px;
`

const ErrowSvg = styled.svg`
  box-sizing: border-box;
  margin: 0px 0px 0px 8px;
  min-width: 0px;
  color: currentcolor;
  position: relative;
  left: 8px;
  transition: all 0.2s ease 0s;
  width: 16px;
  height: 16px;
`

const StartButton = styled.div`
  box-sizing: border-box;
  min-width: 0px;
  appearance: none;
  text-align: center;
  text-decoration: none;
  background-color: #25273d;
  border: 0px;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  line-height: 2.1em;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  border-radius: 32px;
  transition: background 200ms ease 0s;
  display: flex;
  margin: 0px auto;
  padding: 8px 40px;
  -webkit-box-align: center;
  align-items: center;
  :hover {
    background-color: #787a9b;
  }
  :hover ${ErrowSvg} {
    left: 16px;
  }
`

export default Home
