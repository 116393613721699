import { fork, all } from 'redux-saga/effects';
import { watchWallet } from './WalletActions';
import { watchPool } from './PoolActions';


export default function* root() {
    yield all([
        fork(watchWallet),
        fork(watchPool)
    ])
};