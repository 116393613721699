export enum SubmitButtonStatusType {
  DISABLE,
  READY,
  PROCESS
}

export enum OverviewStatusType {
  EMPTY,
  LOADING,
  READY
}

export type AssetState = {
  address: string;
  def_interest_model: string;
  interest_model: number;
  supply_index: number;
  borrow_index: number;
  borrow_interest_rate: number;
  supply_interest_rate: number;
  insurance_balance: number;
  insurance_ratio: number;
  token: string;
  normalized_total_borrow: number;
  ltv: number;
  liquidation_threshold: number;
  liquidation_bonus: number;
  last_update_epoch: number;
  stable_borrow_last_update: number;
  stable_borrow_amount: number;
  stable_avg_rate: number;
  vault_amount: number;
}

export type PoolData = {
  symbol: string;
  iconUrl: string;
  address: string;
  token: string;
  tokenSymbol: string;
  supplyIndex: number;
  borrowIndex: number;
  borrowInterestRate: number;
  supplyInterestRate: number;
  insuranceBalance: number;
  insuranceRatio: number;
  normalizedTotalBorrow: number;
  ltv: number;
  liquidationThreshold: number;
  liquidationBonus: number;
  lastUpdateEpoch: number;
  stableBorrowLastUpdate: number;
  stableBorrowAmount: number;
  stableAvgRate: number;
  currentEpoch: number;
  tokenTotalSupply: number;
  price: number;
  priceInXrd: number;
  tokenPrice: number;
  tokenPriceInXrd: number;
  sort: number;
  vaultAmount: number;
}

export type CollateralDebtPosition = {
  borrow_token: string;
  collateral_token: string;
  is_stable: number;
  total_borrow: number;
  total_repay: number;
  normalized_borrow: number;
  collateral_amount: number;
  borrow_amount: number;
  last_update_epoch: number;
  stable_rate: number;
  is_burned: boolean;
  non_fungible_id: string
}


export type CDPData = {
  borrowTokenSymbol: string;
  borrowToken: string;
  collateralTokenSymbol: string;
  collateralToken: string;
  isStable: boolean;
  totalBorrow: number;
  totalRepay: number;
  normalizedBorrow: number;
  collateralAmount: number;
  borrowAmount: number;
  stableRate: number;
  isBurned: boolean;
  nonFungibleId: string;
}