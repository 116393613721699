const initState = {
    xrd_usdt:{},
    xrd_usdc:{},
    // price_map:{}
}


export enum CommonActionType {
    INIT,
    SET_PRICE,
    SET_PRICE_MAP,
}

const Common = (state=initState, action: {type: CommonActionType, payload:any}) => {
    switch (action.type) {
        case CommonActionType.SET_PRICE:
          return {...state, xrd_usdt:action.payload.xrd_usdt, xrd_usdc:action.payload.xrd_usdc}
        case CommonActionType.SET_PRICE_MAP:
          return {...state, price_map:action.payload}
        default :
          return state;
    }
}

export default Common;