import { StateEntityDetailsVaultResponseItem, RadixDappToolkit, StateEntityMetadataPageResponse } from "@radixdlt/radix-dapp-toolkit";
import { CDPData, PoolData } from "../component/Types";
import { AssetState } from "../component/Types";
import CONFIG from '../env/index'

// const getStateFromEntityDetailData = (entityDetail:StateEntityDetailsVaultResponseItem):any=>{
//   const detail:any =  entityDetail.details
//   return detail.state;
// }

export function getCurrentIndex(assetState:AssetState, currentEpoch:number):number[]{
  const deltaEpoch = currentEpoch - assetState.last_update_epoch;
  const deltaEpochYear = deltaEpoch/CONFIG.EPOCH_OF_YEAR;
  const deltaBorrowInterestRate = assetState.borrow_interest_rate * deltaEpochYear;
  const deltaSupplyInterestRate = assetState.supply_interest_rate * deltaEpochYear;
  
  return [assetState.supply_index * (1 + deltaSupplyInterestRate), assetState.borrow_index * (1 + deltaBorrowInterestRate)];

}

export function getCurrentIndexByPoolData(poolData:PoolData):number[]{
  const deltaEpoch = poolData.currentEpoch - poolData.lastUpdateEpoch;
  const deltaEpochYear = deltaEpoch/CONFIG.EPOCH_OF_YEAR;
  const deltaBorrowInterestRate = poolData.borrowInterestRate * deltaEpochYear;
  const deltaSupplyInterestRate = poolData.supplyInterestRate * deltaEpochYear;
  return [poolData.supplyIndex * (1 + deltaSupplyInterestRate), poolData.borrowIndex * (1 + deltaBorrowInterestRate)];
}

export function getStableCurrentIndex(poolData:PoolData, cdp:CDPData):number{
  const deltaEpoch = poolData.currentEpoch - poolData.lastUpdateEpoch;
  return (1+cdp.stableRate * deltaEpoch/CONFIG.EPOCH_OF_YEAR);
}

export async function genCdpArray(rdt:RadixDappToolkit|null, address:string, handle:Function){
  const ret:CDPData[] = [];
  const entityDetailResult = rdt?.gatewayApi.state.getEntityDetailsVaultAggregated(address);  
  
  entityDetailResult?.then(entityDetail=>{
    const noFungibleItems = entityDetail.non_fungible_resources.items;
    if(noFungibleItems !== undefined && noFungibleItems.length > 0){
      const cdpItems = noFungibleItems.find(item=>item.resource_address === CONFIG.CDP_ADDRESS)?.vaults.items;
      if(cdpItems !== undefined && cdpItems.length > 0){
        const ids = cdpItems[0].items;
        if(ids !== undefined && ids.length > 0){
          const cdpsResult = rdt?.gatewayApi.state.getNonFungibleData(CONFIG.CDP_ADDRESS, ids);
          cdpsResult?.then(cdp=>{
            
            cdp.forEach(item=>{
              const data = item.data;
              const programJson:any = data?.programmatic_json;
              const borrowToken = programJson.fields.find((it:any)=>it.field_name === 'borrow_token').value;
              const borrowTokenSymbol = CONFIG.POOL_TOKENS.find((it:any)=>it.value === borrowToken)?.label;
              const collateralToken = programJson.fields.find((it:any)=>it.field_name === 'collateral_token').value;
              const collateralTokenSymbol = CONFIG.DEPOSIT_TOKENS.find((it:any)=>it.value === collateralToken)?.label;
              const cdpData:CDPData = {
                borrowTokenSymbol: borrowTokenSymbol === undefined ? '' : borrowTokenSymbol,
                borrowToken: borrowToken,
                collateralTokenSymbol: collateralTokenSymbol === undefined ? '' : collateralTokenSymbol,
                collateralToken: collateralToken,
                isStable: Boolean(programJson.fields.find((it:any)=>it.field_name === 'is_stable').value),
                totalBorrow: programJson.fields.find((it:any)=>it.field_name === 'total_borrow').value,
                totalRepay: programJson.fields.find((it:any)=>it.field_name === 'total_repay').value,
                normalizedBorrow: programJson.fields.find((it:any)=>it.field_name === 'normalized_borrow').value,
                collateralAmount: programJson.fields.find((it:any)=>it.field_name === 'collateral_amount').value,
                borrowAmount: programJson.fields.find((it:any)=>it.field_name === 'borrow_amount').value,
                stableRate: programJson.fields.find((it:any)=>it.field_name === 'stable_rate').value,
                isBurned: item.is_burned,
                nonFungibleId: item.non_fungible_id
              }
              ret.push(cdpData);
            })
            handle(ret);
          });
        }else{handle([])}
      }else{handle([])}
    }else{handle([])}
  });
  
}


/**
 * 
 * @param rdt symbol: string;
 */
export async function genPoolDataByAssetSate(rdt:RadixDappToolkit|null, assetState:AssetState, assetPriceMap:Map<string,number>):Promise<PoolData|undefined>{
  const currentEpoch = await queryCurrentEpoch(rdt);
  const supply = await queryAssetSupplyByAddress(rdt, assetState.token);
  const iconUrl = await queryAssetIconUrl(rdt, assetState.address);
  
  let symbol = CONFIG.POOL_TOKENS.find((token:any)=>token.value===assetState.address)?.label;
  let dxSymbol = CONFIG.DEPOSIT_TOKENS.find((token:any)=>token.value===assetState.token)?.label;
  let sort = CONFIG.POOL_TOKENS.find((token:any)=>token.value===assetState.address)?.sort;

  if(symbol !== undefined && dxSymbol !== undefined){
    let assetPrice = assetPriceMap.get(symbol);
    let assetPriceInXrd = assetPriceMap.get(symbol + '_IN_XRD');
    if(assetPrice !== undefined && assetPriceInXrd){
      let tokenPrice = assetPrice / assetState.borrow_index;
      let tokenPriceInXrd = assetPriceInXrd / assetState.borrow_index;
      let poolData:PoolData = {
        symbol: symbol,
        iconUrl: iconUrl,
        address: assetState.address,
        token: assetState.token,
        tokenSymbol: dxSymbol,
        supplyIndex: assetState.supply_index,
        borrowIndex: assetState.borrow_index,
        borrowInterestRate: assetState.borrow_interest_rate,
        supplyInterestRate: assetState.supply_interest_rate,
        insuranceBalance: assetState.insurance_balance,
        insuranceRatio: assetState.insurance_ratio,
        normalizedTotalBorrow: assetState.normalized_total_borrow,
        ltv: assetState.ltv,
        liquidationThreshold: assetState.liquidation_threshold,
        liquidationBonus: assetState.liquidation_bonus,
        lastUpdateEpoch: assetState.last_update_epoch,
        stableBorrowLastUpdate: assetState.stable_borrow_last_update,
        stableBorrowAmount: assetState.stable_borrow_amount,
        stableAvgRate: assetState.stable_avg_rate,
        currentEpoch: currentEpoch,
        tokenTotalSupply: supply,
        price: assetPrice,
        priceInXrd: assetPriceInXrd,
        tokenPrice: tokenPrice,
        tokenPriceInXrd: tokenPriceInXrd,
        vaultAmount: assetState.vault_amount,
        sort: sort === undefined ? -1 : sort,
      }
      return poolData;
    }
  }
}

/**
 * 
 * @param assetState 4.  利用率：[variable_normal_amount * borrowIndex + stable_normal_amount * (1+(current_epoch - last_update_epoch) / 15017 * stable_avg_rate)] / (dxToken.totalSupply * supplyIndex)
 * @param assetPrice 
 * @param currentEpoch 
 * @returns 
 */
export function getUtilizationFactor(assetState:AssetState, supply:number, currentEpoch:number):number{
  const [supplyIndex, borrowIndex] = getCurrentIndex(assetState, currentEpoch);
  const variableNormalAmount = assetState.normalized_total_borrow;
  if(supply === 0){
    return 0;
  }else{
    return (variableNormalAmount*borrowIndex + assetState.stable_borrow_amount * (1+(currentEpoch - assetState.last_update_epoch))/CONFIG.EPOCH_OF_YEAR * assetState.stable_avg_rate) / (supply * supplyIndex);
  }
}

export function getUtilizationFactorByPoolData(poolData:PoolData){
  const [supplyIndex, borrowIndex] = getCurrentIndexByPoolData(poolData);
  const variableNormalAmount = poolData.normalizedTotalBorrow;
  if(poolData.tokenTotalSupply === 0){
    return 0;
  }else{
    return (variableNormalAmount * borrowIndex + poolData.stableBorrowAmount * (1+(poolData.currentEpoch - poolData.lastUpdateEpoch)/CONFIG.EPOCH_OF_YEAR * poolData.stableAvgRate)) / (poolData.tokenTotalSupply * supplyIndex);
  }
}

export async function queryCurrentEpoch(rdt:RadixDappToolkit|null):Promise<number>{
  const result = await rdt?.gatewayApi.status.getCurrent();
  const epoch = result?.ledger_state.epoch;
  return epoch === undefined ? -1 : epoch;
}

export async function queryAssetSupplyByAddress(rdt:RadixDappToolkit|null, address:string):Promise<number>{
  const result:StateEntityDetailsVaultResponseItem|undefined = await rdt?.gatewayApi.state.getEntityDetailsVaultAggregated(address);
  const details:any = result?.details;
  return Number(details.total_supply);
}

export async function queryAssetPriceMapFromOracle(rdt:RadixDappToolkit|null):Promise<Map<string,number>>{
  const result:StateEntityDetailsVaultResponseItem|undefined = await rdt?.gatewayApi.state.getEntityDetailsVaultAggregated(CONFIG.COMPONENT_ORACLE_ADDRESS);
  const details:any = result?.details;
  const fields = details.state.fields;
  const usdtInXrd = fields.find((item:any)=>item.kind==="Decimal" && item.field_name==='usdt_price').value;
  const usdcInXrd = fields.find((item:any)=>item.kind==="Decimal" && item.field_name==='usdc_price').value;
  let assetPriceMap = new Map();
  assetPriceMap.set('XRD', Number((1/usdtInXrd).toFixed(2)));
  assetPriceMap.set('USDT', 1);
  assetPriceMap.set('USDC', Number((usdcInXrd/usdtInXrd).toFixed(2)));
  assetPriceMap.set('XRD_IN_XRD', 1);
  assetPriceMap.set('USDT_IN_XRD', usdtInXrd);
  assetPriceMap.set('USDC_IN_XRD', usdcInXrd);

  return assetPriceMap;
}

export async function queryAssetStates(rdt:RadixDappToolkit|null, callBack:Function):Promise<AssetState[]>{
  let ret:AssetState[] = [];
  const result:StateEntityDetailsVaultResponseItem|undefined = await rdt?.gatewayApi.state.getEntityDetailsVaultAggregated(CONFIG.CDP_MANAGER_COMPONENT_ADDRESS);
  
  const detail:any = result?.details
  const state = detail.state;
  const pools = state.fields.find((item:any)=>item.field_name === 'pools');
  const states = state.fields.find((item:any)=>item.field_name === 'states');

  var taskWatcher = pools.entries.length
  pools.entries.forEach((entry:any)=>{
    let resourceAddress = entry.key.value
    let poolComponentAddress = entry.value.value
    let stateEntry = states.entries.find((item:any)=>item.key.value === resourceAddress).value
    let collateralToken = String(stateEntry.fields.find((item:any)=>item.field_name === 'collateral_token').value);
    let ltv = Number(stateEntry.fields.find((item:any)=>item.field_name === 'ltv').value)
    let liquidationThreshold = Number(stateEntry.fields.find((item:any)=>item.field_name === 'liquidation_threshold').value)
    let liquidationBonus = Number(stateEntry.fields.find((item:any)=>item.field_name === 'liquidation_bonus').value)
    const result = genAssetState(rdt, resourceAddress, poolComponentAddress, collateralToken, ltv, liquidationThreshold, liquidationBonus);
    result.then(state=>{
      ret.push(state);
      taskWatcher--;
      if(taskWatcher === 0){
        callBack(ret);
      }
    })
  })

  return ret;
}

export async function genAssetState(rdt:RadixDappToolkit|null, resourceAddress:string, poolComponentAddress:string, collateralToken:string, ltv:number, liquidationThreshold:number, liquidationBonus:number){
  const result:any = await rdt?.gatewayApi.state.getEntityDetailsVaultAggregated(poolComponentAddress);
  const stateDetail = result.details.state
  const vaultAmount = Number(result.fungible_resources.items.find((item:any)=>item.resource_address === resourceAddress).vaults.items[0].amount)
  const fields = stateDetail.fields
  let state:AssetState = {
    address: resourceAddress,
    def_interest_model: "",
    interest_model: Number(fields.find((item:any) => item.field_name === 'interest_model').variant_id),
    supply_index: Number(fields.find((item:any) => item.field_name === 'deposit_index').value),
    borrow_index: Number(fields.find((item:any) => item.field_name === 'loan_index').value),
    borrow_interest_rate: Number(fields.find((item:any) => item.field_name === 'variable_loan_interest_rate').value),
    supply_interest_rate: Number(fields.find((item:any) => item.field_name === 'deposit_interest_rate').value),
    insurance_balance: Number(fields.find((item:any) => item.field_name === 'insurance_balance').value),
    insurance_ratio: Number(fields.find((item:any) => item.field_name === 'insurance_ratio').value),
    token: collateralToken,
    normalized_total_borrow: Number(fields.find((item:any) => item.field_name === 'variable_loan_share_quantity').value),
    ltv: ltv,
    liquidation_threshold: liquidationThreshold,
    liquidation_bonus: liquidationBonus,
    last_update_epoch: Number(fields.find((item:any) => item.field_name === 'last_update').value),
    stable_borrow_last_update: Number(fields.find((item:any) => item.field_name === 'stable_loan_last_update').value),
    stable_borrow_amount: Number(fields.find((item:any) => item.field_name === 'stable_loan_amount').value),
    stable_avg_rate: Number(fields.find((item:any) => item.field_name === 'stable_loan_interest_rate').value),
    vault_amount: vaultAmount
  };

  return state;
}

export async function queryAssetStatesOld(rdt:RadixDappToolkit|null):Promise<AssetState[]>{
  const result:StateEntityDetailsVaultResponseItem|undefined = await rdt?.gatewayApi.state.getEntityDetailsVaultAggregated(CONFIG.LENDING_FACTORY_COMPONENT_ADDRESS);
  const detail:any = result?.details;
  const fungibleResource = result?.fungible_resources;
  const states = detail.state.fields.find((item:any)=>item.field_name === 'states');
  
  let ret:AssetState[] = [];

  
  
  states.entries.forEach((entry:any)=>{
    const address = entry.key.value;
    const fields = entry.value.fields;
    let state:AssetState = {
      address: address,
      def_interest_model: String(fields.find((item:any) => item.field_name === 'def_interest_model').value),
      interest_model: Number(fields.find((item:any) => item.field_name === 'interest_model').variant_id),
      supply_index: Number(fields.find((item:any) => item.field_name === 'supply_index').value),
      borrow_index: Number(fields.find((item:any) => item.field_name === 'borrow_index').value),
      borrow_interest_rate: Number(fields.find((item:any) => item.field_name === 'borrow_interest_rate').value),
      supply_interest_rate: Number(fields.find((item:any) => item.field_name === 'supply_interest_rate').value),
      insurance_balance: Number(fields.find((item:any) => item.field_name === 'insurance_balance').value),
      insurance_ratio: Number(fields.find((item:any) => item.field_name === 'insurance_ratio').value),
      token: String(fields.find((item:any) => item.field_name === 'token').value),
      normalized_total_borrow: Number(fields.find((item:any) => item.field_name === 'normalized_total_borrow').value),
      ltv: Number(fields.find((item:any) => item.field_name === 'ltv').value),
      liquidation_threshold: Number(fields.find((item:any) => item.field_name === 'liquidation_threshold').value),
      liquidation_bonus: Number(fields.find((item:any) => item.field_name === 'liquidation_bonus').value),
      last_update_epoch: Number(fields.find((item:any) => item.field_name === 'last_update_epoch').value),
      stable_borrow_last_update: Number(fields.find((item:any) => item.field_name === 'stable_borrow_last_update').value),
      stable_borrow_amount: Number(fields.find((item:any) => item.field_name === 'stable_borrow_amount').value),
      stable_avg_rate: Number(fields.find((item:any) => item.field_name === 'stable_avg_rate').value),
      vault_amount: Number(fungibleResource?.items.find((item:any)=>item.resource_address === address)?.vaults.items[0].amount)
    };
    
    ret.push(state);
    
  })
  
  return ret;
}

export function numberToFactor(data:number, toFix:number=4):string{
  if(data !== undefined){
    return (data*100).toFixed(toFix).concat('%');
  }else{
    return '0%'
  }
}

export function numberToPrice(data:number, toFix:number=4):string{
  if(data !== undefined){
    return "$".concat(data.toFixed(toFix));
  }else{
    return '$0';
  }
}

export function getDxTokenAddress(address:string){
  return CONFIG.WITHDRAW_GENERATE_DIC.find((item:any)=>item.generate === address)?.address
}

export async function queryAssetBalance(rdt:RadixDappToolkit|null, account:string, resource:string):Promise<number>{
  const result = await rdt?.gatewayApi.state.getEntityDetailsVaultAggregated(account);
  const vault = result?.fungible_resources.items.find((item)=>item.resource_address === resource);
  const amount = vault?.vaults.items[0].amount;
  if(amount !== undefined){
    return Number(amount);
  }else{
    return 0;
  }
}

export async function queryAssetIconUrl(rdt:RadixDappToolkit|null, address:string){
  const result = await rdt?.gatewayApi.state.getEntityMetadata(address);
  const typed = result?.items.find(item=>item.key === 'icon_url')?.value.typed;
  if(typed && (typed.type === 'Url' || typed.type === 'String')){
    return typed.value;
  }else{
    return '';
  }
}

export async function queryAssetMetaData(rdt:RadixDappToolkit|null, address:string):Promise<StateEntityMetadataPageResponse|undefined>{
  const result = await rdt?.gatewayApi.state.getEntityMetadata(address);
  return result;
}

// export function getCdpLiquidationPrice(poolDataArray:PoolData[], cdp:CDPData|undefined){
//   if(cdp === undefined){
//     return -1;
//   }
//   const poolData = poolDataArray.find(item=>item.address === cdp.borrowToken);
//   const collateralData = poolDataArray.find(item=>item.token === cdp.collateralToken);
//   if(poolData === undefined || collateralData === undefined){
//     return -1;
//   }
//   if(collateralData.ltv <=0){
//     return -1;
//   }
//   // console.log('liquidation price', poolData.price, cdp.normalizedBorrow, poolData.liquidationThreshold, cdp.collateralAmount);
//   return poolData.price * cdp.normalizedBorrow / collateralData.liquidationThreshold / cdp.collateralAmount
// }

export function getBellowCdpLiqudationPrice(poolDataArray:PoolData[], cdp:CDPData, depositToken:string):number{
  const liquidationPrice = getLiquidationPrice(poolDataArray, cdp, depositToken, 0, cdp.borrowToken, 0);
  const depositPoolData = poolDataArray.find(item=>item.token === cdp.collateralToken);
  let currentPrice:number|undefined = 0;
  if(depositToken === cdp.collateralToken){
    currentPrice = depositPoolData?.tokenPrice;
  }else if(depositToken === depositPoolData?.address){
    currentPrice = depositPoolData.price;
  }
  if(currentPrice !== undefined){
    return (currentPrice - liquidationPrice) / currentPrice
  }else{
    return 0;
  }
  
}

/**
 * 
 * @param poolDataArray 
 * @param deposit 
 * @param depositAmount 
 * @param borrow 
 * @param borrowAmount 
 */
export function getLiquidationPrice(poolDataArray:PoolData[], cdp:CDPData|null, deposit:string, depositAmount:number, borrow:string, borrowAmount:number):number{
  const borrowPoolData = poolDataArray.find(item=>item.address === borrow);
  let depositPoolData = poolDataArray.find(item=>item.token === cdp?.collateralToken);
  if(borrowPoolData === undefined){
    return 0;
  }
  
  if(cdp === null){ // create position
    depositPoolData = poolDataArray.find(item=>item.token === deposit);
    if(depositPoolData === undefined){
      return 0;
    }
    if(borrowAmount <=0){
      return 0;
    }else{
      if(depositAmount <= 0 || depositPoolData.liquidationThreshold <= 0){
        return 0;
      }else{
        return borrowPoolData.priceInXrd * borrowAmount / depositPoolData.liquidationThreshold / depositAmount
      }
    }
  }else{
    if(depositPoolData === undefined || depositPoolData.ltv <= 0){
      return 0;
    }
    if(Number(depositAmount) + Number(cdp.collateralAmount) === 0){
      return 0
    }
    let val:number = 0
    if(cdp.isStable){
      val = (Number(cdp.borrowAmount * getStableCurrentIndex(borrowPoolData, cdp))  + Number(borrowAmount)) * borrowPoolData.price / depositPoolData.liquidationThreshold / (Number(depositAmount) + Number(cdp.collateralAmount)) 
    }else{
      val = (Number(cdp.normalizedBorrow * borrowPoolData.borrowIndex)  + Number(borrowAmount)) * borrowPoolData.price / depositPoolData.liquidationThreshold / (Number(depositAmount) + Number(cdp.collateralAmount)) 
    }
    
    if(deposit === cdp?.collateralToken){
      return val <= 0 ? 0 : val;
    }else if(deposit === depositPoolData?.address){
      return val <= 0 ? 0 : val * depositPoolData?.supplyIndex;;
    }else{
      return 0;
    }
  
  }
}

export function genCdpLoanToValue(poolDataArray:PoolData[], cdp:CDPData){
  const depositPoolData = poolDataArray.find(item=>item.token === cdp.collateralToken);
  const borrowPoolData = poolDataArray.find(item=>item.address === cdp.borrowToken);
  if(depositPoolData === undefined || borrowPoolData === undefined){
    return 0;
  }

  const collateralPrice = depositPoolData?.tokenPrice;
  const borrowPrice = borrowPoolData?.price;

  if(collateralPrice !== undefined && borrowPrice !== undefined){
    if(cdp.isStable){
      if(cdp.borrowAmount === 0){
        return 0;
      }
      return collateralPrice * cdp.collateralAmount / (borrowPrice * cdp.borrowAmount * getStableCurrentIndex(borrowPoolData,cdp));
    }else{
      if(cdp.normalizedBorrow === 0){
        return 0;
      }
      const [supplyIndex, borrowIndex] = getCurrentIndexByPoolData(borrowPoolData);
      if(Number(cdp.normalizedBorrow) > 0){
        return collateralPrice * cdp.collateralAmount / (borrowPrice * cdp.normalizedBorrow * borrowIndex);
      }else{
        return 0
      }
    }
    
  }else{
    return 0
  }
  
}

export function genLoanToValue(poolDataArray:PoolData[], cdp:CDPData|null, depositToken:string, depositAmount:number, borrowToken:string, borrowAmount:number){
  const borrowPoolData = poolDataArray.find(item=>item.address === borrowToken);
  let depositPoolData = poolDataArray.find(item=>item.token === cdp?.collateralToken)
  if(borrowPoolData === undefined){
    return 0;
  }
  if(cdp === null){
    
    depositPoolData = poolDataArray.find(item=>item.token === depositToken)
    
    if(borrowAmount === 0 || depositPoolData === undefined){
      return 0;
    }else{
      return depositPoolData.tokenPriceInXrd * depositAmount / borrowPoolData.priceInXrd / borrowAmount;  
    }
  }else{
    if(depositPoolData === undefined){
      return 0;
    }
    let totalDeposit:number = 0;
    if(depositToken === cdp.collateralToken){
      totalDeposit = Number(cdp.collateralAmount) + Number(depositAmount);
    }else if (depositToken === depositPoolData.address){
      totalDeposit = (Number(cdp.collateralAmount) + Number(depositAmount) * depositPoolData.supplyIndex);
    }
    
    if(totalDeposit === 0){
      return 0;
    }else{
      let totalBorrow = 0;
      if(cdp.isStable){
        totalBorrow = Number(cdp.borrowAmount * getStableCurrentIndex(borrowPoolData, cdp)) + Number(borrowAmount);
      }else{
        totalBorrow = Number(cdp.normalizedBorrow * borrowPoolData.borrowIndex) + Number(borrowAmount);
      }
      if(totalBorrow === 0){
        return 0;
      }else{
        return totalBorrow <=0 ? 0 : depositPoolData.tokenPriceInXrd * totalDeposit / borrowPoolData.priceInXrd / totalBorrow;
      }
    }
  }
}

export function genVaultDebt (poolDataArray:PoolData[], cdp:CDPData|undefined, borrow:string, borrowAmount:number):number{
  if(cdp !== null && cdp!==undefined){
    const borrowPoolData = poolDataArray.find(item=>item.address === cdp.borrowToken);
    if(borrowPoolData === undefined){
      return 0;
    }
    const [supplyIndex, borrowIndex] = getCurrentIndexByPoolData(borrowPoolData);
    if(cdp.isStable){
      return Number(cdp.borrowAmount * getStableCurrentIndex(borrowPoolData, cdp)) + Number(borrowAmount);
      // return borrowPoolData.price * (cdp.borrowAmount * getStableCurrentIndex(borrowPoolData, cdp)) + Number(borrowPoolData.price * borrowAmount);
    }else{
      let ret = Number(cdp.normalizedBorrow * borrowIndex) + Number(borrowAmount)
      return ret < 0 ? 0 : ret;
      // return Number(borrowPoolData.price * (cdp.normalizedBorrow * borrowIndex)) + Number(borrowPoolData.price * borrowAmount);
    }
  }else{
    const borrowPoolData = poolDataArray.find(item=>item.address === borrow);
    return borrowPoolData === undefined ? 0 : borrowPoolData.price * borrowAmount;
  }
}

export function genTotalExposure(poolDataArray:PoolData[], cdp:CDPData|null, depositToken:string, depositAmount:number){
  if(cdp === null){
    return depositAmount;
  }else{
    return depositAmount;
  }
}

export function genPositionDebet(poolDataArray:PoolData[], cdp:CDPData|null, borrowToken:string, borrowAmount:number){
  if(cdp === null){
    return borrowAmount;
  }else{
    return borrowAmount;
  }
}

export function genMaxBorrowByDeposit(poolDataArray:PoolData[], depositToken:string, depositAmount:number, borrowToken:string){
  const borrowPoolData = poolDataArray.find(item=>item.address === borrowToken);
  const depositPoolData = poolDataArray.find(item=>item.token === depositToken)
  if(borrowPoolData === undefined || depositPoolData === undefined){
    return 0;
  }
  if(depositPoolData.ltv<= 0){
    return 0;
  }
  const maxAmount = depositPoolData.tokenPriceInXrd * depositAmount * depositPoolData.ltv / borrowPoolData.priceInXrd;
  return maxAmount > borrowPoolData.vaultAmount ? borrowPoolData.vaultAmount: maxAmount;
}

export function genCollateralLockedTotalValue(poolDataArray:PoolData[], cdp:CDPData, deposit:string='', depositAmount:number=0, borrow:string='', borrowAmount:number=0):number{
  if(depositAmount === 0 && borrowAmount === 0){
    const depositPoolData = poolDataArray.find(item=>item.token === cdp.collateralToken);
    if(depositPoolData !== undefined){
      return depositPoolData.tokenPrice * cdp.collateralAmount;
    }else{
      return 0;
    }
  }else{
    const depositPoolData = poolDataArray.find(item=>item.token === cdp.collateralToken);
    // const borrowPoolData = poolDataArray.find(item=>item.address === cdp.borrowToken);
    if(depositPoolData === undefined){
      return 0;
    }
    return depositPoolData.tokenPrice * (Number(cdp.collateralAmount) + Number(depositAmount));
  }
  
}

export function genAvaliableToWithdraw(poolDataArray:PoolData[], cdp:CDPData,  deposit:string='', depositAmount:number=0, borrow:string='', borrowAmount:number=0):number{
    const borrowPoolData = poolDataArray.find(item=>item.address === cdp.borrowToken);
    const depositPoolData = poolDataArray.find(item=>item.token === cdp.collateralToken);
    if(borrowPoolData !== undefined && depositPoolData !== undefined){
      const [supplyIndex, borrowIndex] = getCurrentIndexByPoolData(borrowPoolData);
      let amt = 0;
      if(cdp.isStable){
        amt = borrowPoolData.priceInXrd * (Number(cdp.borrowAmount * getStableCurrentIndex(borrowPoolData,cdp)) + Number(borrowAmount)) / depositPoolData.liquidationThreshold / Number(depositPoolData.tokenPriceInXrd);
      }else{
        amt = borrowPoolData.priceInXrd * (Number(cdp.normalizedBorrow * borrowIndex) + Number(borrowAmount)) / depositPoolData.liquidationThreshold / Number(depositPoolData.tokenPriceInXrd);
      }
      
      if(deposit === cdp.collateralToken){
        return (Number(cdp.collateralAmount) + Number(depositAmount) - Number(amt));
      }else if(deposit === depositPoolData.address){
        return (Number(cdp.collateralAmount) + Number(depositAmount) - Number(amt)) * depositPoolData.supplyIndex;
      }else{
        return 0;
      }
      
    }else{
      return 0;
    }
}

export function genAvailibleToGenerate(poolDataArray:PoolData[], cdp:CDPData, deposit:string='', depositAmount:number=0, borrow:string='', borrowAmount:number=0):number{
    const borrowPoolData = poolDataArray.find(item=>item.address === cdp.borrowToken);
    const depositPoolData = poolDataArray.find(item=>item.token === cdp.collateralToken);
    if(borrowPoolData !== undefined && depositPoolData !== undefined){
      const [supplyIndex, borrowIndex] = getCurrentIndexByPoolData(borrowPoolData);
      const amt = depositPoolData.tokenPrice * (Number(cdp.collateralAmount) + Number(depositAmount)) * depositPoolData.liquidationThreshold / borrowPoolData.price;

      if(cdp.isStable){
        const retNum = (Number(amt) - Number(cdp.borrowAmount * getStableCurrentIndex(borrowPoolData, cdp)) - Number(borrowAmount));
        return retNum > borrowPoolData.vaultAmount ? borrowPoolData.vaultAmount : retNum;
      }else{
        const retNum = (Number(amt) - Number(cdp.normalizedBorrow * borrowIndex) - Number(borrowAmount));
        return retNum > borrowPoolData.vaultAmount ? borrowPoolData.vaultAmount : retNum;
      }
      
    }else{
      return 0;
    }
}

export function formatNumber(value:Number|undefined, fixed:number=6){
  if(value){
    if(value.toString().search('e') === -1){
      if(fixed > 0){
        return value.toFixed(fixed)
      }else{
        return value.toString();
      }
    }else{
      const toExp = value.toExponential();
      const digit1 = Number(toExp.split('-')[1]);
      const digit2 = Number(toExp.split('.')[1].split('e-')[0].length)
      if(fixed > 0){
        return value.toFixed(fixed)
      }else{
        return value.toFixed(digit1+digit2);
      }
    }
  }else{
    return '0';
  }
}

export function getFixLength(value:string){
  if(value.search('e') === -1){
    if(value.includes('.')){
      return value.split('.')[1].length;
    }else{
      return 0;
    }
  }else{
    return 0;
  }
}

export function genManifestPriceParams(act:string, borrowTokenAddress:string, collateralTokenAddress:string, CONFIG:any, COMMON:any, cdp:CDPData|null=null):{price1:string,quote1:string,timestamp1:string,signature1:string,
  price2:string,quote2:string,timestamp2:string,signature2:string}
  {

    const priceMap = COMMON.price_map;
    var price1:string = 'None'
    let quote1:string = 'None'
    let timestamp1:string = 'None'
    let signature1:string = 'None'
    let price2:string ='None'
    let quote2:string = 'None'
    let timestamp2:string = 'None'
    var signature2:string = 'None'

    if(act === 'borrow_variable'){
      let generateTokenSymbol = CONFIG.BORROW_RESOURCE_LIST.find((item:any)=>item.value === borrowTokenAddress).key;
      let collateralTokenSymbol = CONFIG.DEPOSIT_TOKENS.find((item:any)=>item.value === collateralTokenAddress).key;
      
      if(generateTokenSymbol === 'XRD'){
        let addr = CONFIG.WITHDRAW_GENERATE_DIC.find((item:any)=>item.address === collateralTokenAddress).generate
        let symbol = CONFIG.POOL_TOKENS.find((item:any)=>item.value === addr).key
        price1 = priceMap.get('XRD_IN_'+symbol);
        quote1 = addr
        timestamp1 = COMMON['xrd_'+symbol.toLowerCase()].data.timestamp
        signature1 = COMMON['xrd_'+symbol.toLowerCase()].data.signature
      }else if(collateralTokenSymbol === 'dxXRD'){
        let symbol = CONFIG.POOL_TOKENS.find((item:any)=>item.value === borrowTokenAddress).key
        price1 = priceMap.get('XRD_IN_'+symbol);
        quote1 = borrowTokenAddress
        timestamp1 = COMMON['xrd_'+generateTokenSymbol.toLowerCase()].data.timestamp
        signature1 = COMMON['xrd_'+generateTokenSymbol.toLowerCase()].data.signature
      }else{
        let symbol1 = CONFIG.POOL_TOKENS.find((item:any)=>item.value === borrowTokenAddress).key
        price1 = priceMap.get('XRD_IN_'+symbol1);
        quote1 = borrowTokenAddress
        timestamp1 = COMMON['xrd_'+generateTokenSymbol.toLowerCase()].data.timestamp
        signature1 = COMMON['xrd_'+generateTokenSymbol.toLowerCase()].data.signature

        let addr2 = CONFIG.WITHDRAW_GENERATE_DIC.find((item:any)=>item.address === collateralTokenAddress).generate
        let symbol2 = CONFIG.POOL_TOKENS.find((item:any)=>item.value === addr2).key
        price2 = priceMap.get('XRD_IN_'+symbol2);
        quote2 = addr2
        timestamp2 = COMMON['xrd_'+symbol2.toLowerCase()].data.timestamp
        signature2 = COMMON['xrd_'+symbol2.toLowerCase()].data.signature
      }
    }else if(act === 'extend_borrow' || act === 'withdraw_collateral'){
      let generateTokenSymbol = CONFIG.BORROW_RESOURCE_LIST.find((item:any)=>item.value === cdp?.borrowToken).key;
      let collateralTokenSymbol = CONFIG.DEPOSIT_TOKENS.find((item:any)=>item.value === cdp?.collateralToken).key;
      let collateralSourceTokenAddress = CONFIG.WITHDRAW_GENERATE_DIC.find((item:any) => item.address === cdp?.collateralToken).generate
      let collateralSourceTokenSymbol = CONFIG.POOL_TOKENS.find((item:any)=>item.value === collateralSourceTokenAddress).key;

      if(generateTokenSymbol === 'XRD'){
        let addr = CONFIG.WITHDRAW_GENERATE_DIC.find((item:any)=>item.address === cdp?.collateralToken).generate
        let symbol = CONFIG.POOL_TOKENS.find((item:any)=>item.value === addr).key
        price1 = priceMap.get('XRD_IN_'+symbol);
        quote1 = cdp?.collateralToken === undefined ? "" : cdp?.collateralToken
        timestamp1 = COMMON['xrd_'+collateralSourceTokenSymbol.toLowerCase()].data.timestamp
        signature1 = COMMON['xrd_'+collateralSourceTokenSymbol.toLowerCase()].data.signature
      }else if(collateralTokenSymbol === 'dxXRD'){
        let symbol = CONFIG.POOL_TOKENS.find((item:any)=>item.value === cdp?.borrowToken).key
        price1 = priceMap.get('XRD_IN_'+symbol);
        quote1 = cdp?.borrowToken === undefined ? "" : cdp.borrowToken
        timestamp1 = COMMON['xrd_'+generateTokenSymbol.toLowerCase()].data.timestamp
        signature1 = COMMON['xrd_'+generateTokenSymbol.toLowerCase()].data.signature
      }else{
        let addr1 = cdp?.borrowToken
        let symbol1 = CONFIG.POOL_TOKENS.find((item:any)=>item.value === addr1).key
        price1 = priceMap.get('XRD_IN_'+symbol1);
        quote1 = cdp?.borrowToken === undefined ? "" : cdp.borrowToken
        timestamp1 = COMMON['xrd_'+generateTokenSymbol.toLowerCase()].data.timestamp
        signature1 = COMMON['xrd_'+generateTokenSymbol.toLowerCase()].data.signature

        price2 = priceMap.get('XRD_IN_'+collateralSourceTokenSymbol);
        quote2 = cdp?.collateralToken === undefined ? "" : collateralSourceTokenAddress
        timestamp2 = COMMON['xrd_'+collateralSourceTokenSymbol.toLowerCase()].data.timestamp
        signature2 = COMMON['xrd_'+collateralSourceTokenSymbol.toLowerCase()].data.signature
      }
    }
    
    return {price1,quote1,timestamp1,signature1,price2,quote2,timestamp2,signature2}
}


