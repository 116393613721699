var env = undefined
var config:any
// var config_file = 'stokenet.json'
var config_file = 'mainnet.json'
try {
  config = require('./config-env/' + config_file)
  config.POOL_TOKENS = [
    {
      label: 'XRD',
      key: 'XRD',
      value: config.XRD_ADDRESS,
      sort: 1,
    },
    {
      label: 'USDT',
      key: 'USDT',
      value: config.USDT_ADDRESS,
      sort: 2,
    },
    {
      label: 'USDC',
      key: 'USDC',
      value: config.USDC_ADDRESS,
      sort: 3,
    },
  ]

  config.DEFAULT_POOL_TOKEN_ADDRESS = config.XRD_ADDRESS
  config.DEPOSIT_TOKENS = [
    { label: 'dxXRD', key: 'dxXRD', value: config.DX_XRD_ADDRESS, sort: 1 },
    { label: 'dxUSDT', key: 'dxUSDT', value: config.DX_USDT_ADDRESS, sort: 2 },
    { label: 'dxUSDC', key: 'dxUSDC', value: config.DX_USDC_ADDRESS, sort: 3 },
  ]
  config.BORROW_RESOURCE_LIST = [
    { label: 'XRD', key: 'XRD', value: config.XRD_ADDRESS, sort: 1 },
    { label: 'USDT', key: 'USDT', value: config.USDT_ADDRESS, sort: 2 },
    { label: 'USDC', key: 'USDC', value: config.USDC_ADDRESS, sort: 3 },
  ]
  config.DEPOSIT_GENERATE_DIC = [
    {
      address: config.DX_XRD_ADDRESS, //dxXRD
      items: [
        { label: 'USDT', key: 'USDT', value: config.USDT_ADDRESS, sort: 1 },
        { label: 'USDC', key: 'USDC', value: config.USDC_ADDRESS, sort: 2 },
      ],
    },
    {
      address: config.DX_USDT_ADDRESS, //dxUSDT
      items: [
        { label: 'XRD', key: 'XRD', value: config.XRD_ADDRESS, sort: 1 },
        { label: 'USDC', key: 'USDC', value: config.USDC_ADDRESS, sort: 2 },
      ],
    },
    {
      address: config.DX_USDC_ADDRESS, //dxUSDC
      items: [
        { label: 'XRD', key: 'XRD', value: config.XRD_ADDRESS, sort: 1 },
        { label: 'USDT', key: 'USDT', value: config.USDT_ADDRESS, sort: 1 },
      ],
    },
  ]
  config.WITHDRAW_GENERATE_DIC = [
    {
      address: config.DX_XRD_ADDRESS,
      generate: config.XRD_ADDRESS,
    },
    {
      address: config.DX_USDT_ADDRESS,
      generate: config.USDT_ADDRESS,
    },
    {
      address: config.DX_USDC_ADDRESS,
      generate: config.USDC_ADDRESS,
    },
  ]
} catch (err:any) {
  if (err.code && err.code === 'MODULE_NOT_FOUND') {
    console.error(
      'No config file matching ENV=' + env + '. Requires "' + env + '.json"'
    )
    process.exit(1)
  } else {
    throw err
  }
}

export default config
