import { WalletDataState } from "@radixdlt/radix-dapp-toolkit";

const initState = {
    connected: false,
    // accounts: [],
    // personaData: {},
    // proofs: {},
    // persona: {}
    walletDataState: {}
}

export enum WalletActionType {
    INIT="WALLET.INIT",
    HANDLE_WALLET_DATA="WALLET.HANDLE_WALLETDATA",
}

const Wallet = (state=initState, action: {type: string, payload:any}) => {
    switch (action.type) {
        case WalletActionType.HANDLE_WALLET_DATA:
            const payload:WalletDataState = action.payload;
            if(payload && payload.accounts && payload.accounts.length > 0){
                return {...initState, connected:true, walletDataState:payload}
            }else{
                return {...initState, connected:false, walletDataState:payload}
            }
        default :
         return state;
    }
}

export default Wallet;