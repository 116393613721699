import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import NavigateButtons from '../component/NavigateButtons'
import AccountPicker from '../component/AccountPicker'
import { SelectListItem } from '../component/SelectComponent'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Empty } from 'antd'
import { Spin } from 'antd'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { useRdt } from '../hooks/useRdt'
import {
  genCdpArray,
  queryAssetStates,
  genPoolDataByAssetSate,
} from '../utils/ContractDataUtil'
import { CDPData, PoolData } from '../component/Types'
import CdpCard from '../component/CdpCard'

interface Props {
  device: string
}

enum CDPListStatus {
  INIT,
  READY,
}

const CDP: React.FC<Props> = (props) => {
  const navigate = useNavigate()

  const [status, setStatus] = useState<CDPListStatus>(0)
  const walletData = useSelector((state: any) => state.Wallet)
  const priceMap = useSelector((state:any)=> state.Common.price_map)
  const rdt = useRdt()

  const [account, setAccount] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [poolDataArray, setPoolDataArray] = useState<PoolData[]>([])
  const [cdpArray, setCdpArray] = useState<CDPData[]>([])

  useEffect(() => {
    initData()
  }, [walletData.connected, priceMap])

  const initData = async () => {
    if (walletData.connected) {
      if(poolDataArray.length > 0){
        freshPrice()
      }else{
      setAccount(walletData.walletDataState.accounts[0].appearanceId.toString())
      walletData.walletDataState.accounts.forEach((acct: any) => {
        if (
          acct.appearanceId.toString() ===
          walletData.walletDataState.accounts[0].appearanceId.toString()
        ) {
          setAddress(acct.address)
          queryData(acct.address)
        }
      })
    }
    }
  }

  const freshPrice = ()=>{
    if(priceMap && poolDataArray){
      poolDataArray.forEach(item=>{
        item.price = priceMap.get(item.symbol)
        item.priceInXrd = priceMap.get(item.symbol + '_IN_XRD')
        item.tokenPrice = priceMap.get(item.symbol) / item.borrowIndex
        item.tokenPriceInXrd = priceMap.get(item.symbol + '_IN_XRD') / item.borrowIndex
      })
    }
  }

  const queryData = async (address: string) => {
    setStatus(CDPListStatus.INIT)
    if (walletData.connected === false) {
      return
    }
    genCdpArray(rdt, address, handleCdpArray)
  }

  const handleCdpArray = async (cdpArray: CDPData[]) => {
    setCdpArray(cdpArray)
      if(!priceMap){
        return
      }
      queryAssetStates(rdt, handleAssetStates);
      // assetStatesResult.then((assetStates) => {
      //   taskWatcher = assetStates.length
      //   assetStates.forEach((assetState) => {
      //     const poolDataResult = genPoolDataByAssetSate(
      //       rdt,
      //       assetState,
      //       priceMap
      //     )
      //     poolDataResult.then((poolData) => {
      //       if (poolData !== undefined) {
      //         poolDataArray?.push(poolData)
      //         taskWatcher--
      //         poolDataArray.sort((a, b) => a.sort - b.sort)
      //         setPoolDataArray(poolDataArray)
      //       }
      //       if (taskWatcher === 0) {
      //         setStatus(CDPListStatus.READY)
      //       }
      //     })
      //   })
      // })
  }

  const handleAssetStates = async (assetStates:any)=>{
    let taskWatcher = assetStates.length;
    let dataArray: PoolData[] = []
    assetStates.forEach((assetState:any) => {
      const poolDataResult = genPoolDataByAssetSate(
        rdt,
        assetState,
        priceMap
      )
      poolDataResult.then((poolData) => {
        if (poolData !== undefined) {
          dataArray?.push(poolData)
          taskWatcher--
          dataArray.sort((a, b) => a.sort - b.sort)
          
        }
        if (taskWatcher === 0) {
          setPoolDataArray(dataArray)
          setStatus(CDPListStatus.READY)
        }
      })
    })

  }

  const changeAccount = (value: string) => {
    setAccount(value)
    walletData.walletDataState.accounts.forEach((acct: any) => {
      if (acct.appearanceId.toString() === value) {
        setAddress(acct.address)
        queryData(acct.address)
      }
    })
  }

  const genAccountList = () => {
    var items: SelectListItem[] = []
    if (walletData.connected) {
      walletData.walletDataState.accounts.forEach((acct: any) => {
        let addr = acct.address
        items.push({
          label:
            acct.label +
            '(' +
            addr.substring(0, 4) +
            '...' +
            addr.substring(addr.length - 6, addr.length) +
            ')',
          key: acct.appearanceId.toString(),
          value: acct.appearanceId.toString(),
          sort: -1,
        })
      })
    }
    return items
  }

  const getCDPCardList = () => {
    if (status === CDPListStatus.INIT) {
      return (
        <div style={{ textAlign: 'center', marginTop: '128px' }}>
          <Spin
            indicator={
              <Loading3QuartersOutlined
                style={{ fontSize: '37px', color: '#787A9B' }}
                spin
              />
            }
          ></Spin>
        </div>
      )
    } else {
      if (cdpArray.length > 0) {
        const cdpCardList = cdpArray.map((item, index) => (
          <CdpCard
            device={props.device}
            data={item}
            poolArray={poolDataArray}
            key={index}
            address={address}
            from="list"
            opt={null}
            depositToken={item.collateralToken}
            depositValue={0}
            borrowToken={item.borrowToken}
            borrowValue={0}
          ></CdpCard>
        ))
        return <DataZone device={props.device}>{cdpCardList}</DataZone>
      } else {
        return (
          <div style={{ textAlign: 'center', marginTop: '128px' }}>
            <Empty />
          </div>
        )
      }
    }
  }

  return (
    <Layout>
      <LayoutContainer>
        <NavigateButtons />
        <div style={{ paddingTop: '16px' }}>
          <FlexZone>
            <div style={{ width: '50%' }}>
              <AccountPicker
                disable={walletData.connected ? false : true}
                device={props.device}
                onSelect={changeAccount}
                defaultValue={account}
                items={genAccountList()}
              />
            </div>
            <div style={{ width: '50%', textAlign: 'center' }}>
              <SubmitButton
                onClick={() => {
                  navigate('/borrow/')
                }}
              >
                Open a Collateral Debt Position
              </SubmitButton>
            </div>
          </FlexZone>
        </div>
        {getCDPCardList()}
      </LayoutContainer>
    </Layout>
  )
}

const Layout = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  display: flex;
`
const LayoutContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  position: relative;
`

const DataZone = styled.div`
  gap: ${(props: { device: String }) =>
    props.device === 'mobile' ? '24px' : '48px'};
  grid-template-columns: ${(props: { device: String }) =>
    props.device === 'mobile' ? '2f4' : '2fr 2fr'};
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  align-items: flex-start;
`

const FlexZone = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const SubmitButton = styled.button`
  box-sizing: border-box;
  margin: 0px 6px;
  min-width: 0px;
  appearance: none;
  text-align: center;
  text-decoration: none;
  padding: 8px 16px;
  background-color: #25273d;
  border: 0px;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  line-height: 2.1em;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  border-radius: 32px;
  transition: background 200ms ease 0s;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  :hover {
    background-color: #787a9b;
  }
`

export default CDP
