import { combineReducers } from "redux";
import Wallet from './Wallet';
import Pool from './Pool';
import Common from './Common';

const appReducer = combineReducers({
    Wallet,
    Pool,
    Common
});

const rootReducer = (state:any, action:{type:string,payload:any}) => {
    let isGoToRoot = action.type === '@@router/LOCATION_CHANGE' && action.payload.pathname === '/'
    if (action.type === 'GLOBAL.CLEAR_SESSION_FULFILLED' || isGoToRoot) {
    }
    return appReducer(state, action)
}

export default rootReducer;