import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams, Navigate, useNavigate } from 'react-router-dom'
import CONFIG from '../env/index'
import { Alert, Spin, notification } from 'antd'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import NumericInput from '../component/NumericInput'
import { CDPData, PoolData, SubmitButtonStatusType } from '../component/Types'
import { useRdt } from '../hooks/useRdt'
import { useSelector } from 'react-redux'
import NavigateButtons from '../component/NavigateButtons'
import {
  genVaultDebt,
  formatNumber,
  genAvailibleToGenerate,
  genAvaliableToWithdraw,
  genCdpArray,
  queryAssetBalance,
  genManifestPriceParams,
  queryAssetStates,
  genPoolDataByAssetSate,
  getFixLength,
} from '../utils/ContractDataUtil'
import CdpCard from '../component/CdpCard'

interface Props {
  device: string
}

const ManageCdp: React.FC<Props> = (props) => {
  const [notificationApi, contextHolder] = notification.useNotification()
  const rdt = useRdt()
  const walletData = useSelector((state: any) => state.Wallet)
  const priceMap = useSelector((state: any) => state.Common.price_map)
  const common = useSelector((state: any) => state.Common)
  const params = useParams()
  const navigate = useNavigate()

  const [submitButtonStatus, setSubmitButtonStatus] =
    useState<SubmitButtonStatusType>(SubmitButtonStatusType.DISABLE)
  const [poolDataArray, setPoolDataArray] = useState<PoolData[]>([])
  const [cdpData, setCdpData] = useState<CDPData>()
  const [opt, setOpt] = useState<string>('deposit')

  const [address, setAddress] = useState<string>()
  const [cdpId, setCdpId] = useState<string>()
  const [collateralToken, setCollateralToken] = useState<string>()
  const [collateralBalance, setCollateralBallance] = useState<number>()

  const [depositValue, setDepositValue] = useState<string>('')
  const [withdrawValue, setWithdrawValue] = useState<string>('')
  const [generateValue, setGenerateValue] = useState<string>('')
  const [repayValue, setRepayValue] = useState<string>('')

  const [depositAlertMessage, setDepositAlertMessage] = useState<string>('')
  const [isShowDepositAlert, setIsShowDepositAlert] = useState<boolean>(false)
  const [withdrawAlertMessage, setWithdrawAlertMessage] = useState<string>('')
  const [isShowWithdrawAlert, setIsShowWithdrawAlert] = useState<boolean>(false)
  const [generateAlertMessage, setGenerateAlertMessage] = useState<string>('')
  const [isShowGenerateAlert, setIsShowGenerateAlert] = useState<boolean>(false)
  const [repayAlertMessage, setRepayAlertMessage] = useState<string>('')
  const [isShowRepayAlert, setIsShowRepayAlert] = useState<boolean>(false)

  const [borrowToken, setBorrowToken] = useState<string>()
  const [borrowBalance, setBorrowBalance] = useState<number>()

  useEffect(() => {
    initData()
  }, [walletData.connected, priceMap])

  if (params.addr === undefined || params.cdp === undefined) {
    return <Navigate to="/" />
  }

  const initData = async () => {
    if (walletData.connected) {
      if (priceMap && poolDataArray.length > 0) {
        freshPrice()
      } else {
        walletData.walletDataState.accounts.forEach((acct: any) => {
          if (acct.address === params.addr) {
            setAddress(acct.address)
            setCdpId('#' + params.cdp + '#')
            queryData(acct.address)
          }
        })
      }
    }
  }

  const freshPrice = () => {
    if (priceMap && poolDataArray.length > 0) {
      poolDataArray.forEach((item) => {
        item.price = priceMap.get(item.symbol)
        item.priceInXrd = priceMap.get(item.symbol + '_IN_XRD')
        item.tokenPrice = priceMap.get(item.symbol) / item.borrowIndex
        item.tokenPriceInXrd = priceMap.get(item.symbol + '_IN_XRD') / item.borrowIndex
      })
    }
  }

  const queryData = async (address: string) => {
    if (
      walletData.connected === false &&
      address !== undefined &&
      address !== ''
    ) {
      return
    }
    genCdpArray(rdt, address, handleCdpArray)
  }

  const handleCdpArray = async (cdpArray: CDPData[]) => {
    const cdpData = cdpArray.find(
      (item) => item.nonFungibleId === '#' + params.cdp + '#'
    )
    if (cdpData === undefined) {
      navigate('/home')
      return
    }
    setCdpData(cdpData)

    setBorrowToken(cdpData.borrowToken)
    fetchBorrowTokenBalance(params.addr, cdpData.borrowToken)

    if (!priceMap) {
      return
    }
    queryAssetStates(rdt, handleAssetStates)

  }

  const handleAssetStates = async (assetStates:any)=>{
    let taskWatcher = assetStates.length;
    let dataArray: PoolData[] = []
    assetStates.forEach((assetState:any) => {
      const poolDataResult = genPoolDataByAssetSate(rdt, assetState, priceMap)
      poolDataResult.then((poolData) => {
        if (poolData !== undefined) {
          dataArray?.push(poolData)
          dataArray.sort((a, b) => a.sort - b.sort)
          taskWatcher--
        }
        if (taskWatcher === 0) {
          setPoolDataArray(dataArray);
          const depositToken = dataArray.find(
            (item) => item.token === cdpData?.collateralToken
          )?.address
          if (depositToken !== undefined) {
            setCollateralToken(depositToken)
            fetchCollateralTokenBalance(params.addr, depositToken)
          }
        }
      })
    })

  }

  const fetchBorrowTokenBalance = async (
    address: string | undefined,
    resourceAddress: string
  ) => {
    if (walletData.connected && address && resourceAddress && address) {
      const balanceResult = queryAssetBalance(rdt, address, resourceAddress)
      balanceResult.then((balance) => {
        setBorrowBalance(balance)
      })
    }
  }

  const fetchCollateralTokenBalance = async (
    address: string | undefined,
    resourceAddress: string
  ) => {
    if (walletData.connected && address && resourceAddress && address) {
      const balanceResult = queryAssetBalance(rdt, address, resourceAddress)
      balanceResult.then((balance) => {
        setCollateralBallance(balance)
      })
    }
  }

  const onDepositInputChange = (value: string) => {
    if (!walletData.connected) {
      return
    }
    setDepositValue(value)

    if (Number(value) > Number(collateralBalance)) {
      setDepositAlertMessage(
        'You cannot withdraw more collateral than the amount in your wallet'
      )
      setIsShowDepositAlert(true)
      setSubmitButtonStatus(SubmitButtonStatusType.DISABLE)
    } else {
      setDepositAlertMessage('')
      setIsShowDepositAlert(false)
      if (Number(value) === 0) {
        setSubmitButtonStatus(SubmitButtonStatusType.DISABLE)
      } else {
        setSubmitButtonStatus(SubmitButtonStatusType.READY)
      }
    }
  }

  const onWithdrawInputChange = (value: string) => {
    if (!walletData.connected) {
      return
    }
    setWithdrawValue(value)
    const maxWithdrawValue =
      cdpData === undefined
        ? 0
        : genAvaliableToWithdraw(
            poolDataArray,
            cdpData,
            collateralToken,
            0,
            cdpData.borrowToken,
            0
          )
    if (Number(value) > maxWithdrawValue) {
      setWithdrawAlertMessage(
        'You cannot withdraw more than ' + maxWithdrawValue.toFixed(4)
      )
      setIsShowWithdrawAlert(true)
      setSubmitButtonStatus(SubmitButtonStatusType.DISABLE)
    } else {
      setWithdrawAlertMessage('')
      setIsShowWithdrawAlert(false)
      if (Number(value) === 0) {
        setSubmitButtonStatus(SubmitButtonStatusType.DISABLE)
      } else {
        setSubmitButtonStatus(SubmitButtonStatusType.READY)
      }
    }
  }

  const onGenerateInputChange = (value: string) => {
    if (!walletData.connected) {
      return
    }

    setGenerateValue(value)
    const maxGenerateValue =
      cdpData === undefined ? 0 : genAvailibleToGenerate(poolDataArray, cdpData)
    if (Number(value) > maxGenerateValue) {
      setGenerateAlertMessage(
        'You cannot borrow more than ' + maxGenerateValue.toFixed(4)
      )
      setIsShowGenerateAlert(true)
      setSubmitButtonStatus(SubmitButtonStatusType.DISABLE)
    } else {
      setGenerateAlertMessage('')
      setIsShowGenerateAlert(false)
      if (Number(value) === 0) {
        setSubmitButtonStatus(SubmitButtonStatusType.DISABLE)
      } else {
        setSubmitButtonStatus(SubmitButtonStatusType.READY)
      }
    }
  }

  const onRepayMax = (value: string) => {
    let v = Math.floor(Number(value) * 1000000) / 1000000
    onRepayInputChange(v.toString())
  }

  const onRepayInputChange = (value: string) => {
    if (!walletData.connected) {
      return
    }

    if (getFixLength(value) > 6) {
      return
    }

    setRepayValue(value)
    setRepayAlertMessage('')
    setIsShowRepayAlert(false)
    if (Number(value) === 0) {
      setSubmitButtonStatus(SubmitButtonStatusType.DISABLE)
    } else {
      setSubmitButtonStatus(SubmitButtonStatusType.READY)
    }

    // const maxValue = cdpData === undefined ? 0 : genVaultDebt(poolDataArray, cdpData, cdpData.borrowToken, 0);
    // if(Number(value) > maxValue){
    //   setRepayAlertMessage("You cannot repay more than "+ maxValue.toFixed(6));
    //   setIsShowRepayAlert(true);
    //   setSubmitButtonStatus(SubmitButtonStatusType.DISABLE);
    // }else{
    //   setRepayAlertMessage("");
    //   setIsShowRepayAlert(false);
    //   if(Number(value) === 0){
    //     setSubmitButtonStatus(SubmitButtonStatusType.DISABLE);
    //   }else{
    //     setSubmitButtonStatus(SubmitButtonStatusType.READY);
    //   }
    // }
  }

  const clearInput = () => {
    setDepositValue('')
    setIsShowDepositAlert(false)
    setWithdrawValue('')
    setIsShowWithdrawAlert(false)
    setGenerateValue('')
    setIsShowGenerateAlert(false)
    setRepayValue('')
    setIsShowRepayAlert(false)
  }

  const genCardDepositToken = () => {
    return collateralToken === undefined ? '' : collateralToken
    // switch(opt){
    //   case "deposit":
    //     return collateralToken === undefined ? '' : collateralToken;
    //   case "withdraw":
    //     return collateralToken === undefined ? '' : collateralToken;
    //   default:
    //     return '';
    // }
  }

  const genCardBorrowToken = () => {
    return borrowToken === undefined ? '' : borrowToken
    // switch(opt){
    //   case "generate":
    //     return borrowToken === undefined ? '' : borrowToken;
    //   case "repay":
    //     return borrowToken === undefined ? '' : borrowToken;
    //   default:
    //     return '';
    // }
  }

  const genCardDepositValue = () => {
    switch (opt) {
      case 'deposit':
        return Number(depositValue)
      case 'withdraw':
        return Number(withdrawValue)
      default:
        return 0
    }
  }

  const genCardBorrowValue = () => {
    switch (opt) {
      case 'generate':
        return Number(generateValue)
      case 'repay':
        return Number(repayValue)
      default:
        return 0
    }
  }

  const sendTransaction = () => {
    if (opt === 'deposit') {
      doDeposit()
    } else if (opt === 'withdraw') {
      doWithdraw()
    } else if (opt === 'generate') {
      doGenerate()
    } else if (opt === 'repay') {
      doRepay()
    }
  }

  const doDeposit = async () => {
    setSubmitButtonStatus(SubmitButtonStatusType.PROCESS)

    let manifest = `
      CALL_METHOD
        Address("${address}")
        "withdraw"
        Address("${collateralToken}")
        Decimal("${depositValue}");

      TAKE_FROM_WORKTOP
        Address("${collateralToken}")
        Decimal("${depositValue}")
        Bucket("bucket1");

      CALL_METHOD
        Address("${CONFIG.LENDING_FACTORY_COMPONENT_ADDRESS}")
        "addition_collateral"
        ${params.cdp}u64
        Bucket("bucket1")
      ;

      CALL_METHOD
        Address("${address}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP")
      ;
    `
    console.log(manifest)
    const result = await rdt?.walletApi.sendTransaction({
      transactionManifest: manifest,
    })
    if (result?.isErr()) {
      setSubmitButtonStatus(SubmitButtonStatusType.READY)
      notificationApi.error({
        message: '',
        description: result.error.message,
        placement: 'bottomRight',
      })
      return
    }
    setSubmitButtonStatus(SubmitButtonStatusType.READY)

    let ret: any = result
    notificationApi.success({
      message: '',
      description: (
        <div style={{ marginTop: '16px' }}>
          <a
            target="_blank"
            rel="noreferrer"
            href={
              CONFIG.DASHBOARD_URL +
              ret.value.transactionIntentHash +
              '/details'
            }
          >
            {ret.value.transactionIntentHash}
          </a>
        </div>
      ),
      placement: 'bottomRight',
    })
    queryData(address === undefined ? '' : address)
  }

  const doWithdraw = async () => {
    setSubmitButtonStatus(SubmitButtonStatusType.PROCESS)

    let params = genManifestPriceParams("extend_borrow", "", "", CONFIG, common, cdpData);
    const price2Str = params.price2 === 'None' ? `Enum<0u8>()` : `"${params.price2}"`
    const quote2Str = params.quote2 === 'None' ? `Enum<0u8>()` : `"${params.quote2}"`
    const timestamp2Str = params.timestamp2 === 'None' ? `Enum<0u8>()` : `${params.timestamp2}u64`
    const signature2Str = params.signature2 === 'None' ? `Enum<0u8>()` : `"${params.signature2}"`

    let manifest = `
      CALL_METHOD
        Address("${address}")
        "withdraw_non_fungibles"
        Address("${CONFIG.CDP_ADDRESS}")
        Array<NonFungibleLocalId>(
          NonFungibleLocalId("${cdpId}")
        )
      ;

      TAKE_ALL_FROM_WORKTOP
        Address("${CONFIG.CDP_ADDRESS}")
        Bucket("bucket1")
      ;

      CALL_METHOD
        Address("${CONFIG.LENDING_FACTORY_COMPONENT_ADDRESS}")
        "withdraw_collateral"
        Bucket("bucket1")
        Decimal("${withdrawValue}")
        "${params.price1}"
        Address("${params.quote1}")
        ${params.timestamp1}u64
        "${params.signature1}"
        ${price2Str} 
        ${quote2Str}
        ${timestamp2Str}
        ${signature2Str}
      ;
      
      CALL_METHOD
        Address("${address}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP")
      ;
    `

    console.log(manifest)
    const result = await rdt?.walletApi.sendTransaction({
      transactionManifest: manifest,
    })
    if (result?.isErr()) {
      setSubmitButtonStatus(SubmitButtonStatusType.READY)
      notificationApi.error({
        message: '',
        description: result.error.message,
        placement: 'bottomRight',
      })
      return
    }
    setSubmitButtonStatus(SubmitButtonStatusType.READY)

    let ret: any = result
    notificationApi.success({
      message: '',
      description: (
        <div style={{ marginTop: '16px' }}>
          <a
            target="_blank"
            rel="noreferrer"
            href={
              CONFIG.DASHBOARD_URL +
              ret.value.transactionIntentHash +
              '/details'
            }
          >
            {ret.value.transactionIntentHash}
          </a>
        </div>
      ),
      placement: 'bottomRight',
    })
    queryData(address === undefined ? '' : address)
  }

  const doGenerate = async () => {
    setSubmitButtonStatus(SubmitButtonStatusType.PROCESS)

    let params = genManifestPriceParams("extend_borrow", "", "", CONFIG, common, cdpData);
    const price2Str = params.price2 === 'None' ? `Enum<0u8>()` : `Some("${params.price2}")`
    const quote2Str = params.quote2 === 'None' ? `Enum<0u8>()` : `Some(Address("${params.quote2}"))`
    const timestamp2Str = params.timestamp2 === 'None' ? `Enum<0u8>()` : `Some(${params.timestamp2}u64)`
    const signature2Str = params.signature2 === 'None' ? `Enum<0u8>()` : `Some("${params.signature2}")`

    let manifest = `
      
      CALL_METHOD
        Address("${address}")
        "withdraw_non_fungibles"
        Address("${CONFIG.CDP_ADDRESS}")
        Array<NonFungibleLocalId>(
          NonFungibleLocalId("${cdpId}")
        )
      ;

      TAKE_ALL_FROM_WORKTOP
        Address("${CONFIG.CDP_ADDRESS}")
        Bucket("bucket1")
      ;

      CALL_METHOD
        Address("${CONFIG.LENDING_FACTORY_COMPONENT_ADDRESS}")
        "extend_borrow"
        Bucket("bucket1")
        Decimal("${generateValue}")
        "${params.price1}"
        Address("${params.quote1}")
        ${params.timestamp1}u64
        "${params.signature1}"
        ${price2Str} 
        ${quote2Str}
        ${timestamp2Str}
        ${signature2Str}

      ;
      
      CALL_METHOD
        Address("${address}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP")
      ;
    `

    console.log(manifest)

    const result = await rdt?.walletApi.sendTransaction({
      transactionManifest: manifest,
    })
    if (result?.isErr()) {
      setSubmitButtonStatus(SubmitButtonStatusType.READY)
      notificationApi.error({
        message: '',
        description: result.error.message,
        placement: 'bottomRight',
      })
      return
    }
    setSubmitButtonStatus(SubmitButtonStatusType.READY)

    let ret: any = result
    notificationApi.success({
      message: 'Transaction',
      description: (
        <div style={{ marginTop: '16px' }}>
          <a
            target="_blank"
            rel="noreferrer"
            href={
              CONFIG.DASHBOARD_URL +
              ret.value.transactionIntentHash +
              '/details'
            }
          >
            {ret.value.transactionIntentHash}
          </a>
        </div>
      ),
      placement: 'bottomRight',
    })
    queryData(address === undefined ? '' : address)
  }

  const doRepay = async () => {
    setSubmitButtonStatus(SubmitButtonStatusType.PROCESS)
    let manifest = `
      
      CALL_METHOD
        Address("${address}")
        "withdraw"
        Address("${borrowToken}")
        Decimal("${repayValue}");

      TAKE_FROM_WORKTOP
        Address("${borrowToken}")
        Decimal("${repayValue}")
        Bucket("bucket1");

      CALL_METHOD
        Address("${CONFIG.LENDING_FACTORY_COMPONENT_ADDRESS}")
        "repay"
        Bucket("bucket1")
        ${params.cdp}u64
      ;

      CALL_METHOD
        Address("${address}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP")
      ;
    `

    console.log(manifest)
    const result = await rdt?.walletApi.sendTransaction({
      transactionManifest: manifest,
    })

    if (result?.isErr()) {
      setSubmitButtonStatus(SubmitButtonStatusType.READY)
      notificationApi.error({
        message: '',
        description: result.error.message,
        placement: 'bottomRight',
      })
      return
    }
    setSubmitButtonStatus(SubmitButtonStatusType.READY)

    let ret: any = result
    notificationApi.success({
      message: 'Transaction',
      description: (
        <div style={{ marginTop: '16px' }}>
          <a
            target="_blank"
            rel="noreferrer"
            href={
              CONFIG.DASHBOARD_URL +
              ret.value.transactionIntentHash +
              '/details'
            }
          >
            {ret.value.transactionIntentHash}
          </a>
        </div>
      ),
      placement: 'bottomRight',
    })
    queryData(address === undefined ? '' : address)
  }

  const genRepayButton = () => {
    let debt = genVaultDebt(
      poolDataArray,
      cdpData,
      cdpData ? cdpData.borrowToken : '',
      0
    )
    if (debt === 0) {
      return <ChooseButtonDisable disabled={true}>Repay</ChooseButtonDisable>
    }
    if (opt === 'repay') {
      return <ChooseButton status={1}>Repay</ChooseButton>
    } else {
      return (
        <ChooseButton
          status={0}
          onClick={() => {
            setOpt('repay')
            clearInput()
          }}
        >
          Repay
        </ChooseButton>
      )
    }
  }

  return (
    <Layout>
      {contextHolder}
      <LayoutContainer>
        <NavigateButtons />
        <DataZone device={props.device}>
          <CdpCard
            device={props.device}
            data={cdpData}
            poolArray={poolDataArray}
            address={params.addr}
            from="manage"
            opt={opt}
            depositToken={genCardDepositToken()}
            depositValue={genCardDepositValue()}
            borrowToken={genCardBorrowToken()}
            borrowValue={genCardBorrowValue()}
          />
          <div>
            <OptZone>
              <BoxTitle>
                <BoxTitleContainer>
                  <BoxTitleContent>Manage your CDP</BoxTitleContent>
                </BoxTitleContainer>
              </BoxTitle>

              <BoxLayout>
                <FlexZone>
                  {opt === 'deposit' ? (
                    <ChooseButton status={1}>Deposit</ChooseButton>
                  ) : (
                    <ChooseButton
                      status={0}
                      onClick={() => {
                        setOpt('deposit')
                        clearInput()
                      }}
                    >
                      Deposit
                    </ChooseButton>
                  )}
                  {opt === 'withdraw' ? (
                    <ChooseButton status={1}>Withdraw</ChooseButton>
                  ) : (
                    <ChooseButton
                      status={0}
                      onClick={() => {
                        setOpt('withdraw')
                        clearInput()
                      }}
                    >
                      Withdraw
                    </ChooseButton>
                  )}
                  {opt === 'generate' ? (
                    <ChooseButton status={1}>Borrow</ChooseButton>
                  ) : (
                    <ChooseButton
                      status={0}
                      onClick={() => {
                        setOpt('generate')
                        clearInput()
                      }}
                    >
                      Borrow
                    </ChooseButton>
                  )}
                  {genRepayButton()}
                </FlexZone>
                <Space />

                {opt === 'deposit' ? (
                  <div>
                    <FlexZone>
                      <FormLineTitle style={{ marginBottom: '16px' }}>
                        Deposit
                      </FormLineTitle>
                      <FormLineRight>
                        <div>
                          <NumericInput
                            style={{ width: '', height: 38 }}
                            onChange={onDepositInputChange}
                            value={String(depositValue)}
                            disabled={walletData.connected ? false : true}
                          />
                          <div>
                            <span
                              style={{
                                fontSize: '14px',
                                color: '#003057',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                onDepositInputChange(
                                  formatNumber(collateralBalance)
                                )
                              }}
                            >
                              {formatNumber(collateralBalance)}
                            </span>
                            <span
                              style={{ fontSize: '12px', color: '#8A8FA4' }}
                            >
                              (Available balance)
                            </span>
                          </div>
                        </div>
                        <div>
                          <div style={{ paddingTop: '9px' }}>
                            {
                              poolDataArray.find(
                                (item) =>
                                  item.token === cdpData?.collateralToken
                              )?.symbol
                            }
                          </div>
                        </div>
                      </FormLineRight>
                    </FlexZone>
                    {isShowDepositAlert ? (
                      <Alert
                        style={{ marginTop: '8px', marginBottom: '8px' }}
                        message={depositAlertMessage}
                        type="error"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                {opt === 'withdraw' ? (
                  <div>
                    <FlexZone>
                      <FormLineTitle style={{ marginBottom: '16px' }}>
                        Withdraw
                      </FormLineTitle>
                      <FormLineRight>
                        <div>
                          <NumericInput
                            style={{ width: '', height: 38 }}
                            onChange={onWithdrawInputChange}
                            value={String(withdrawValue)}
                            disabled={walletData.connected ? false : true}
                          />
                          <div>
                            <span
                              style={{
                                fontSize: '14px',
                                color: '#003057',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setWithdrawValue(
                                  formatNumber(
                                    cdpData === undefined
                                      ? 0
                                      : genAvaliableToWithdraw(
                                          poolDataArray,
                                          cdpData,
                                          collateralToken,
                                          0,
                                          cdpData.borrowToken,
                                          0
                                        )
                                  )
                                )
                                setSubmitButtonStatus(
                                  SubmitButtonStatusType.READY
                                )
                              }}
                            >
                              {cdpData === undefined
                                ? 0
                                : formatNumber(
                                    genAvaliableToWithdraw(
                                      poolDataArray,
                                      cdpData,
                                      collateralToken,
                                      0,
                                      cdpData.borrowToken,
                                      0
                                    )
                                  )}
                            </span>
                            <span
                              style={{ fontSize: '12px', color: '#8A8FA4' }}
                            >
                              (max)
                            </span>
                          </div>
                        </div>
                        <div>
                          <div style={{ paddingTop: '9px' }}>
                            {
                              poolDataArray.find(
                                (item) =>
                                  item.token === cdpData?.collateralToken
                              )?.symbol
                            }
                          </div>
                        </div>
                      </FormLineRight>
                    </FlexZone>
                    {isShowWithdrawAlert ? (
                      <Alert
                        style={{ marginTop: '8px', marginBottom: '8px' }}
                        message={withdrawAlertMessage}
                        type="error"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                {opt === 'generate' ? (
                  <div>
                    <FlexZone>
                      <FormLineTitle style={{ marginBottom: '16px' }}>
                        Borrow
                      </FormLineTitle>
                      <FormLineRight>
                        <div>
                          <NumericInput
                            style={{ width: '', height: 38 }}
                            onChange={onGenerateInputChange}
                            value={String(generateValue)}
                            disabled={walletData.connected ? false : true}
                          />
                          <div>
                            <span
                              style={{
                                fontSize: '14px',
                                color: '#003057',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setGenerateValue(
                                  formatNumber(
                                    cdpData === undefined
                                      ? 0
                                      : genAvailibleToGenerate(
                                          poolDataArray,
                                          cdpData
                                        )
                                  )
                                )
                                setSubmitButtonStatus(
                                  SubmitButtonStatusType.READY
                                )
                              }}
                            >
                              {cdpData === undefined
                                ? 0
                                : formatNumber(
                                    genAvailibleToGenerate(
                                      poolDataArray,
                                      cdpData
                                    )
                                  )}
                            </span>
                            <span
                              style={{ fontSize: '12px', color: '#8A8FA4' }}
                            >
                              (max)
                            </span>
                          </div>
                        </div>
                        <div>
                          <div style={{ paddingTop: '9px' }}>
                            {cdpData?.borrowTokenSymbol}
                          </div>
                        </div>
                      </FormLineRight>
                    </FlexZone>
                    {isShowGenerateAlert ? (
                      <Alert
                        style={{ marginTop: '8px', marginBottom: '8px' }}
                        message={generateAlertMessage}
                        type="error"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                {opt === 'repay' ? (
                  <div>
                    <FlexZone>
                      <FormLineTitle style={{ marginBottom: '16px' }}>
                        Repay
                      </FormLineTitle>
                      <FormLineRight>
                        <div>
                          <NumericInput
                            style={{ width: '', height: 38 }}
                            onChange={onRepayInputChange}
                            value={String(repayValue)}
                            disabled={walletData.connected ? false : true}
                          />
                          <div>
                            {/* <span style={{fontSize:'14px', color:'#003057', cursor:'pointer'}} 
                              onClick={()=>{
                                setRepayValue(String(cdpData === undefined ? 0 : genVaultDebt(poolDataArray, cdpData, cdpData.borrowToken, 0)))
                                setSubmitButtonStatus(SubmitButtonStatusType.READY);
                                }}>{cdpData === undefined ? 0 : genVaultDebt(poolDataArray, cdpData, cdpData.borrowToken, 0).toFixed(6)}</span>
                              <span style={{fontSize:'12px',color:'#8A8FA4'}}>(max)</span> */}
                            <span
                              style={{
                                fontSize: '14px',
                                color: '#003057',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                onRepayMax(formatNumber(borrowBalance))
                              }}
                            >
                              {formatNumber(borrowBalance)}
                            </span>
                            <span
                              style={{ fontSize: '12px', color: '#8A8FA4' }}
                            >
                              {' '}
                              (Available balance)
                            </span>
                            <span
                              style={{
                                fontSize: '14px',
                                color: 'rgb(87, 92, 254)',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                onRepayMax(
                                  formatNumber(
                                    genVaultDebt(
                                      poolDataArray,
                                      cdpData,
                                      cdpData ? cdpData.borrowToken : '',
                                      0
                                    )
                                  )
                                )
                              }}
                            >
                              {' '}
                              max
                            </span>
                          </div>
                        </div>
                        <div>
                          <div style={{ paddingTop: '9px' }}>
                            {cdpData?.borrowTokenSymbol}
                          </div>
                        </div>
                      </FormLineRight>
                    </FlexZone>
                    {isShowRepayAlert ? (
                      <Alert
                        style={{ marginTop: '8px', marginBottom: '8px' }}
                        message={repayAlertMessage}
                        type="error"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </BoxLayout>
            </OptZone>

            <SubmitWrapper>
              <SubmitButton
                status={submitButtonStatus}
                disabled={
                  submitButtonStatus === SubmitButtonStatusType.DISABLE ||
                  submitButtonStatus === SubmitButtonStatusType.PROCESS
                    ? true
                    : false
                }
                onClick={sendTransaction}
              >
                {submitButtonStatus === SubmitButtonStatusType.READY ||
                submitButtonStatus === SubmitButtonStatusType.DISABLE ? (
                  'Send Transaction'
                ) : (
                  <Spin
                    indicator={
                      <Loading3QuartersOutlined
                        style={{ fontSize: '37px', color: '#FFF' }}
                        spin
                      />
                    }
                  ></Spin>
                )}
              </SubmitButton>
            </SubmitWrapper>
          </div>
        </DataZone>
      </LayoutContainer>
    </Layout>
  )
}

// Layout
const Layout = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  display: flex;
`
const LayoutContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  position: relative;
`

// Data zone
const DataZone = styled.div`
  gap: ${(props: { device: String }) =>
    props.device === 'mobile' ? '24px' : '48px'};
  grid-template-columns: ${(props: { device: String }) =>
    props.device === 'mobile' ? '2f4' : '2fr minmax(465px, 1fr)'};
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  align-items: flex-start;
`

const BoxTitle = styled.div`
  box-sizing: border-box;
  margin: 0px 16px;
  min-width: 0px;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgb(240, 240, 240);
  display: flex;
`
const BoxTitleContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  -webkit-box-align: center;
  align-items: center;
  height: 40px;
  display: flex;
`
const BoxTitleContent = styled.p`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  color: #25273d;
`

const BoxLayout = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 24px 16px;
`

const OptZone = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  border-radius: 20px;
  background-color: #ffffff;
  position: relative;
  padding: 0px;
  border: 1px solid rgb(240, 240, 240);
  margin-top: 20px;
`

const FlexZone = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const FormLineTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 80px;
  // padding-right: 16px;
  // margin-right: 16px;
  text-align: right;
`
const FormLineRight = styled.div`
  display: grid;
  width: 100%;
  padding-left: 16px;
  grid-template-columns: 2fr 1fr;
  gap: 8px;
`
// submit
const SubmitWrapper = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  padding: 24px;
  // border-top: 1px solid rgb(240, 240, 240);
`
// background-color: var(--theme-ui-colors-neutral60,#BEC9D0);
const SubmitButton = styled.button`
  // pointer-events: none;
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  appearance: none;
  text-align: center;
  text-decoration: none;
  padding: 8px 16px;
  background-color: ${(props: { status: SubmitButtonStatusType }) =>
    props.status === SubmitButtonStatusType.DISABLE ||
    props.status === SubmitButtonStatusType.PROCESS
      ? '#787A9B'
      : '#25273D'};
  border: 0px;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  line-height: 2.1em;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  border-radius: 32px;
  transition: background 200ms ease 0s;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  :hover {
    background-color: #787a9b;
  }
`

const ChooseButtonDisable = styled.button`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  appearance: none;
  text-align: center;
  text-decoration: none;
  padding: 8px 16px;
  background-color: rgb(240, 240, 240);
  border: 0px;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  line-height: 2.1em;
  font-size: 12px;
  color: #596f78;
  cursor: pointer;
  font-weight: 600;
  border-radius: 32px;
  transition: background 200ms ease 0s;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
`

const ChooseButton = styled.button`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  appearance: none;
  text-align: center;
  text-decoration: none;
  padding: 8px 16px;
  background-color: ${(props: { status: number }) =>
    props.status === 1 ? '#575CFE' : 'transparent'};
  border: 0px;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  line-height: 2.1em;
  font-size: 12px;
  color: ${(props: { status: number }) =>
    props.status === 1 ? '#FFF' : '#596F78'};
  cursor: pointer;
  font-weight: 600;
  border-radius: 32px;
  transition: background 200ms ease 0s;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  :hover {
    background-color: ${(props: { status: number }) =>
      props.status === 1 ? '#575CFE' : 'rgb(240, 240, 240)'};
  }
`

const Space = styled.div`
  height: 16px;
`

export default ManageCdp
