import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import { Popover } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { walletDataDefault } from '@radixdlt/radix-dapp-toolkit'
import { useSelector } from 'react-redux'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'radix-connect-button': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

interface Props {
  device: string
}

const items: MenuProps['items'] = [
  {
    key: '1',
    label: (
      <img
        style={{ marginBottom: '4px' }}
        src="https://assets-global.website-files.com/6053f7fca5bf627283b582c2/606d8e7ce4ec3c7f61cde4d9_twitter.svg"
        loading="lazy"
        alt="Twitter"
      ></img>
    ),
  },
  {
    key: '2',
    label: (
      <img
        style={{ marginBottom: '4px' }}
        src="https://assets-global.website-files.com/6053f7fca5bf627283b582c2/606d8e7c998e452b5d60e249_telegram.svg"
        loading="lazy"
        alt="telegram logo"
      ></img>
    ),
  },
  // {
  //   key: '3',
  //   label: (
  //     <img style={{marginBottom:'4px', marginLeft:'-2px'}} src="https://assets-global.website-files.com/6053f7fca5bf627283b582c2/606d8e7c98cbd1d583342f80_discord.svg" loading="lazy" alt="discord logo"></img>
  //   ),
  // },
  {
    key: '4',
    label: (
      <img
        style={{ marginBottom: '4px' }}
        src="https://assets-global.website-files.com/6053f7fca5bf627283b582c2/606d8e7c56b29e6c661143eb_github.svg"
        loading="lazy"
        alt="Github"
      ></img>
    ),
  },
]

const Header: React.FC<Props> = (props) => {
  const walletData = useSelector((state: any) => state.Wallet)

  return (
    <Layout>
      <Wrap>
        <Content1>
          <img alt="Logo" src={require('../assets/logo.png')}></img>
          <BrandName>DeXian</BrandName>
        </Content1>
        <Content2>
          {
            // props.device === 'mobile' ?
            // <>
            //   <div style={{marginRight:'12px'}}>
            //     <Dropdown placement='bottomRight' overlayStyle={{}} menu={{items}}>
            //       <MenuOutlined style={{fontSize:'20px'}}/>
            //     </Dropdown>
            //   </div>
            // </>:
            // <>
            //   <SocialLink target="_blank" href="https://twitter.com/DeXianProtocol"><img src="/social/twitter.svg" loading="lazy" alt="Twitter"></img></SocialLink>
            //   <SocialLink target="_blank" href="https://t.me/dexianio"><img src="/social/telegram.svg" loading="lazy" alt="telegram logo"></img></SocialLink>
            //   {/* <SocialLink target="_blank" ><img src="https://assets-global.website-files.com/6053f7fca5bf627283b582c2/606d8e7c98cbd1d583342f80_discord.svg" loading="lazy" alt="discord logo"></img></SocialLink> */}
            //   <SocialLink target="_blank" href="https://github.com/dexianprotocol"><img src="https://assets-global.website-files.com/6053f7fca5bf627283b582c2/606d8e7c56b29e6c661143eb_github.svg" loading="lazy" alt=""></img></SocialLink>
            // </>
          }
          <Popover
            content={
              <div>
                <div>We have released an enhanced lending protocol</div>
                <a
                  style={{
                    borderRadius: '30px',
                    fontSize: '16px',
                    fontWeight: '500',
                    height: '32px',
                    textAlign: 'center',
                    lineHeight: '32px',
                    textDecoration: 'none',
                    width: '160px',
                    color: '#5aac8f',
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.dexian.io"
                >
                  Getting Start!
                </a>
              </div>
            }
          >
            <div
              style={{ display: 'flex', alignItems: 'center', height: '32px' }}
            >
              <a
                style={{
                  borderRadius: '30px',
                  fontSize: '12px',
                  fontWeight: '500',
                  height: '32px',
                  textAlign: 'center',
                  lineHeight: '32px',
                  textDecoration: 'none',
                  background: 'rgba(90,172,143,.1)',
                  width: '160px',
                  color: '#5aac8f',
                }}
                target="_blank"
                rel="noreferrer"
                href="https://www.dexian.io"
              >
                DeXian Protocol(Newly)
              </a>
            </div>
          </Popover>
          {walletData.connected ? (
            <radix-connect-button></radix-connect-button>
          ) : (
            <></>
          )}
        </Content2>
      </Wrap>
    </Layout>
  )
}

const Layout = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: relative;
  z-index: 3;
`

const Wrap = styled.div`
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
  margin: 8px auto 16px;
  max-width: 1200px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 16px;
  min-height: 83px;
  position: relative;
  z-index: 1;
`

const Content1 = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  width: auto;
  display: flex;
`

const BrandName = styled.h1`
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  padding: 0;
`

const Content2 = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  gap: 16px;
  -webkit-box-align: center;
  align-items: center;
  grid-auto-flow: column;
`

const SocialLink = styled.a`
  cursor: pointer;
`

const PoolLink = styled.button`
  cursor: pointer;
  font-size: 16px;
  color: rgb(89, 111, 120);
  :hover {
    color: rgb(37, 39, 61);
  }
`

export default Header
