import { CommonActionType } from "../reducers/Common";

export function handleAssetPrice(data:any){
  return {
    type: CommonActionType.SET_PRICE,
    payload: data,
  }
}

export function handleAssetPriceMap(data:any){
  return {
    type: CommonActionType.SET_PRICE_MAP,
    payload: data
  }
}