
import styled from 'styled-components';
import { Select, Spin } from 'antd';
import { QuestionCircleOutlined, Loading3QuartersOutlined, SyncOutlined } from '@ant-design/icons';

interface OnSelectFunc {(value:string):void}
export type SelectListItem = {label:string, key:string, value:string, sort:number}

interface Props{
    device: string;
    onSelect: OnSelectFunc;
    defaultValue?: string;
    items: SelectListItem[];
    width?: string;
    height?: string;
    disable?: boolean;
    value?: string;
}

const generateLoading = (props:Props)=>{
  return(
    <SelectWrapper disabled={props.disable ? props.disable : false} width={props.width ? props.width: '100%'} height={props.height ? props.height : '100%'} device={props.device}>
        <div style={{display:'flex', justifyContent:'center'}}><Loading3QuartersOutlined style={{ color:'#8A8FA4'}} spin/></div>
    </SelectWrapper>
  )
}

const generateSelect = (props:Props)=>{
  const genItemList =()=>{
    var itemList: JSX.Element[] = [];
    props.items.forEach((item:SelectListItem)=>{
      itemList.push(<Select.Option value={item.value} key={item.key}>
        <p style={{
          display:'flex', flexDirection:'row', fontWeight:'600', wordBreak:'break-all', wordWrap:'break-word',
          }}>{item.label}
        </p>
      </Select.Option>);
    })
    return itemList;
  }

  return (
    <SelectWrapper 
      disabled={props.disable ? props.disable : false} width={props.width ? props.width: '100%'} 
      height={props.height ? props.height : '100%'} 
      device={props.device}>
      <Select 
        disabled={props.disable ? props.disable : false} 
        style={{width:'100%'}} 
        bordered={false} 
        onSelect={props.onSelect} 
        defaultValue={props.defaultValue}
        value={props.value}>
        {genItemList()}
      </Select>
    </SelectWrapper>
  )
}

const SelectComponent:React.FC<Props> = (props) => {
  // const genItemList =()=>{
  //   var itemList: JSX.Element[] = [];
  //   props.items.forEach((item:SelectListItem)=>{
  //     itemList.push(<Select.Option value={item.value} key={item.key}>
  //       <p style={{display:'flex', flexDirection:'row', fontWeight:'600', wordBreak:'break-all', wordWrap:'break-word'}}>{item.label}
  //       </p>
  //     </Select.Option>);
  //   })
  //   return itemList;
  // }

  if(props.defaultValue === ''){
    return generateLoading(props);
  }else{
    return generateSelect(props);
  }
}

const SelectWrapper = styled.div`
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-color: rgb(234, 234, 234);
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: center;
  min-height: 38px;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  outline: 0px !important;
  heigth: ${(props: {height:string, device:string, width:string}) => props.height ? props.height : '100%'}; 
  width: ${(props: {height:string,device:string, width:string}) => props.width ? props.width : '100%'};
  gap: ${(props: {height:string,device:string, width:string}) => props.device ===  'mobile' ? '24px': '48px'};
  :hover {
    // border-color: rgb(204, 204, 204);
    // border-color: #25273D
    ${(props: {disabled:boolean,height:string,device:string, width:string})=> props.disabled == false ? 'border-color: #69b1ff' : '' }
  }
`



const SelectdUl = styled.ul`
  position: absolute;
  z-index: 100;
  right: 0px;
  left: 0px;
  margin-top: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  list-style: none;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 5px 19px -15px;
`

// const SelectButtonContainer = styled.div`
//   padding-bottom: 8px;
//   width: 100%;
// `

export default SelectComponent;