import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SwitchButtons from '../component/SwitchButtons';
import SelectComponent from '../component/SelectComponent';
// import { LENDING_FACTORY_COMPONENT_ADDRESS, USDT_ADDRESS, CDP_ADDRESS, BORROW_RESOURCE_LIST} from '../constants/cfg';
import CONFIG from '../env/index'
import { useSendTransaction } from '../hooks/useSendTransaction';
import { StateApi, TransactionApi } from '@radixdlt/babylon-gateway-api-sdk';
import { Popover, Alert, Spin, notification, Button } from 'antd';
import { QuestionCircleOutlined, Loading3QuartersOutlined, SyncOutlined } from '@ant-design/icons';
// import { useConnected } from '../hooks/useConnected';
// import { useAccounts } from '../hooks/useAccounts';
import { SelectListItem } from '../component/SelectComponent';
import NumericInput from '../component/NumericInput';
import { OverviewStatusType, SubmitButtonStatusType } from '../component/Types';
// import type {NonFungibleLocalIdString} from '@radixdlt/radix-dapp-toolkit/types';
// import {
//   ManifestBuilder,
//   Decimal,
//   Bucket,
//   Expression,
//   Address,
// } from '@radixdlt/radix-dapp-toolkit';

interface Props{
  device: string
}

const stateApi = new StateApi();
const transactionApi = new TransactionApi();


const Repay:React.FC<Props> = (props) => {

  const [notificationApi, contextHolder] = notification.useNotification();
  
  const sendTransaction = useSendTransaction();
  // const connected = useConnected();
  // const accounts = useAccounts();

  const [account, setAccount] = useState<string>('0');
  const [address, setAddress] = useState<string>('');
  const [borrowResourceAddress, setBorrowResourceAddress] = useState<string>('');
  const [normalizedBorrow, setNormalizedBorrow] = useState<string>('0');
  const [repayAmount, setRepayAmount] = useState<number>(0);
  const [balance, setBalance] = useState<string>('');
  const [cdpList, setCdpList] = useState<SelectListItem[]>([]);
  const [cdp, setCdp] = useState<string>('');

  const [index, setIndex] = useState<string>('0');
  const [rate, setRate] = useState<string>('0');
  const [price, setPrice] = useState<string>('0');

  const [inputValue, setInputValue] = useState<string>('');
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [submitButtonStatus, setSubmitButtonStatus] = useState<SubmitButtonStatusType>(SubmitButtonStatusType.DISABLE)
  const [overviewStatus, setOverviewStatus] = useState<OverviewStatusType>(OverviewStatusType.EMPTY);

  // useEffect(()=>{
  //   if(connected){
  //     accounts.forEach((acct:any)=>{
  //       if(acct.appearanceId.toString() == account){
  //         setAddress(acct.address);
  //         fetchCDPs(acct.address);
  //       }
  //     });
  //   }
  // },[connected]);

  const genAccountList = () => {
    var items:SelectListItem[] = [];
    // if(connected){
    //   accounts.forEach((acct:any)=>{
    //     let addr = acct.address;
    //     items.push({label:acct.label+'('+addr.substring(0,4)+'...'+addr.substring(addr.length-6, addr.length)+')', key:acct.appearanceId.toString(), value:acct.appearanceId.toString()})
    //   });
    // }
    return items;
  }

  const connected = false;

  const fetchCDPs = async (address:string) => {
    if(connected && address){
      let resp = await stateApi.entityNonFungiblesPage({stateEntityNonFungiblesPageRequest:{address:address}});
      let resources = resp.items;
      let cdpResourceAddress = resources.find(item=>item.resource_address === CONFIG.CDP_ADDRESS)?.resource_address;

      if(cdpResourceAddress === undefined){//No Data
        setCdp('N/A');
        setCdpList([]);
        setNormalizedBorrow('0');
        setBorrowResourceAddress('N/A');
        setBalance('0');
        setRepayAmount(0);
        return;
      }

      let resourceVaultResp = await stateApi.entityNonFungibleResourceVaultPage({stateEntityNonFungibleResourceVaultsPageRequest:{address:address, resource_address:cdpResourceAddress}})
      // console.log(resourceVaultResp);
      let vaultAddress = resourceVaultResp.items[0].vault_address;

      let nonFungibleIdsResp = await stateApi.entityNonFungibleIdsPage({stateEntityNonFungibleIdsPageRequest:{address, vault_address:vaultAddress, resource_address:cdpResourceAddress}});
      // console.log(nonFungibleIdsResp);

      let tokenList:SelectListItem[] = [];
      // nonFungibleIdsResp.items.forEach(item=>{
      //   let label = item.non_fungible_id;
      //   let value = item.non_fungible_id;
      //   let key = item.non_fungible_id;
      //   tokenList.push({label: label, value: value, key:key});
      // });
      setCdp(tokenList[0].value);
      setCdpList(tokenList);
      queryCDPData(address, tokenList[0].value);
    }
  }

  const changeInput = (value:string) =>{
    if(!connected){
      return
    }
    setInputValue(value);
    
    updateSubmitButtonStatus(value, balance, overviewStatus);
    updateAlertStatus(value, balance, repayAmount);
  }
  
  const changeAccount = (value:string)=>{
    setAccount(value);
    // let address = accounts.find(account=>account.appearanceId.toString() === value)?.address;
    // if(address !== undefined){
    //   setAddress(address);
    //   setOverviewStatus(OverviewStatusType.EMPTY);
    //   setIndex('');
    //   setPrice('');
    //   setRate('');
    //   fetchCDPs(address);
    // }
  }

  const changeCDP = (value:string)=>{
    setCdp(value);
    queryCDPData(address, value);
  }

  const queryCDPData = async (address:string, value:string)=>{
    // let resp = await stateApi.nonFungibleData({stateNonFungibleDataRequest:{resource_address:CDP_ADDRESS, non_fungible_ids:[value]}});
    // // console.log(resp);
    // let data = resp.non_fungible_ids[0].mutable_data.raw_json;
    // Object.entries(data).forEach(([k,v])=>{
    //   if(k === 'elements'){
    //     let borrowResourceAddress = v[0];
    //     let depositResourceAddress = v[1];
    //     let normalizedBorrow = v[4];
    //     setNormalizedBorrow(normalizedBorrow.value);
    //     setBorrowResourceAddress(borrowResourceAddress.address);
    //     fetchTokenBalance(address, borrowResourceAddress.address);
    //   }
    // });
  }

  const fetchTokenBalance = async (address:string, resourceAddress:string)=>{
    if(connected && address) {
      let resp = await stateApi.entityFungibleResourceVaultPage({stateEntityFungibleResourceVaultsPageRequest:{address:address, resource_address:resourceAddress}});
      if(resp.items.length > 0){
        setBalance(resp.items[0].amount);
      }else{
        setBalance('0')
      }
    }
  }

  const queryData = async () => {
    if(overviewStatus === OverviewStatusType.LOADING || connected === false || borrowResourceAddress === ''){
      return;
    }
    setOverviewStatus(OverviewStatusType.LOADING);
    // let componentAddress = ASSET_COMPONENT_ADDRESS;
    let componentAddress = CONFIG.LENDING_FACTORY_COMPONENT_ADDRESS;
    // let manifest = new ManifestBuilder()
    // .callMethod(componentAddress, "get_current_index", [Address(borrowResourceAddress)])
    // .callMethod(componentAddress, "get_interest_rate", [Address(borrowResourceAddress)])
    // .callMethod(componentAddress, "get_asset_price", [Address(borrowResourceAddress)])
    // .callMethod(componentAddress, "get_asset_price", [Address(USDT_ADDRESS)])
    // .build()
    // .toString();

    // const result = await sendTransaction(manifest);
    
    // if(result.isErr()) {
    //   notificationApi.error({
    //     message: '',
    //     description: "rejectedByUser",
    //     placement: "bottomRight"
    //   })
    //   if(index === '0') {
    //     setOverviewStatus(OverviewStatusType.EMPTY);
    //   }else{
    //     setOverviewStatus(OverviewStatusType.READY);
    //   }
    //   throw result.error;
    // };

    // let status = await transactionApi.transactionStatus({
    //   transactionStatusRequest: {
    //     intent_hash_hex: result.value.transactionIntentHash
    //   }
    // });

    // let commitReceipt = await transactionApi.transactionCommittedDetails({
    //   transactionCommittedDetailsRequest:{
    //     intent_hash_hex: result.value.transactionIntentHash
    //   }
    // });
  
    // console.log("commitReceipt ", commitReceipt);

    // const receipt = commitReceipt.details.receipt;
    
    // Object.entries(receipt).forEach(([k,v])=>{
    //   if(k === 'output'){
    //     let v1 = Number(v[1].data_json.fields[1].value);
    //     let v2 = Number(v[2].data_json.fields[0].value);
    //     let v3 = Number(v[3].data_json.value);
    //     let v4 = Number(v[4].data_json.value); //USDT

    //     setIndex(v1.toFixed(4));
    //     setRate((v2*100).toFixed(4));
    //     setPrice((v3/v4).toFixed(4));
    //     setRepayAmount(Number(normalizedBorrow)*v1);
    //   }
    // });
    // setOverviewStatus(OverviewStatusType.READY);
    // calcGenerateAmount(generateToken, inputValue);
  }


  const updateAlertStatus = (amount:string, balance:string, repayAmount:number) => {
    if(Number(amount) > Number(balance)){
      showAlert("Insufficient account balance");
    }else if(Number(amount) > repayAmount){
      showAlert("Excess will be returned");
    }else{
      hideAlert();
    }
  }

  const updateSubmitButtonStatus = (depositAmount:string, balance:string, overviewStatus:OverviewStatusType) => {
    if(depositAmount === ''){
      setSubmitButtonStatus(SubmitButtonStatusType.DISABLE);
    }else if(Number(depositAmount) > Number(balance)){
      setSubmitButtonStatus(SubmitButtonStatusType.DISABLE);
    }else{
      if(overviewStatus !== OverviewStatusType.READY){
        setSubmitButtonStatus(SubmitButtonStatusType.DISABLE);
      }else{
        setSubmitButtonStatus(SubmitButtonStatusType.READY);
      }
    }
  }

  const doRepay =  async ()=>{
    setSubmitButtonStatus(SubmitButtonStatusType.PROCESS);
    let componentAddress = CONFIG.LENDING_FACTORY_COMPONENT_ADDRESS;
  
    
    // let cdpId:NonFungibleLocalIdString=`#${Number(cdp.replaceAll('#',''))}#`;
    // let manifest = new ManifestBuilder()
    // .withdrawNonFungiblesFromAccount(address, CDP_ADDRESS, [cdpId])
    // .takeFromWorktopByIds([cdpId], CDP_ADDRESS, "cdp")
    // .callMethod(address, "withdraw", [Address(borrowResourceAddress), Decimal(Number(inputValue))])
    // .takeFromWorktop(borrowResourceAddress, "my_bucket")
    // .callMethod(componentAddress, "repay", [Bucket("my_bucket"), Bucket("cdp")])
    // .callMethod(address, "deposit_batch", [Expression("ENTIRE_WORKTOP")])
    // .build()
    // .toString();

    // console.log('supply manifest', manifest);

    // const result = await sendTransaction(manifest);
    
    // if(result.isErr()){
    //   setSubmitButtonStatus(SubmitButtonStatusType.READY);
    //   notificationApi.error({
    //     message: '',
    //     description: "rejectedByUser",
    //     placement: "bottomRight"
    //   })
    //   throw result.error;
    // }
    // setSubmitButtonStatus(SubmitButtonStatusType.READY);
    // fetchTokenBalance(address, borrowResourceAddress);
    // notificationApi.success({
    //   message: '',
    //   description: result.value.transactionIntentHash,
    //   placement: "bottomRight"
    // })
  }

  const maxInputValue = ()=>{
    if(repayAmount > Number(balance)){
      changeInput(balance)
    }else{
      changeInput(repayAmount.toString());
    }
  }

  const showAlert = (message:string)=>{
    setAlertMessage(message);
    setIsShowAlert(true);
  }

  const hideAlert = ()=>{
    setAlertMessage('');
    setIsShowAlert(false);
  }

  return(<Layout>
    {contextHolder}
    <LayoutContainer>
      <Intro>
        <IntroTitle>Dexian Lending Protocol</IntroTitle>
        <IntroContent>DeXian Protocol, bringing greater liquidity and more efficient trading experience to Radix.</IntroContent>
      </Intro>

      <SwitchButtons device={props.device}/>
      
      <DataZone device={props.device}>
        <div>
          <OverviewZone>
            <BoxTitle>
              <BoxTitleContainer>
                <BoxTitleContent>Overview</BoxTitleContent>
              </BoxTitleContainer>
            </BoxTitle>

            <BoxLayout>
              <OverviewData device={props.device}>
                <OverviewDataItem onClick={queryData}>
                  <OverviewDataItemT>Asset Price <Popover placement='topLeft' content="Some description"><QuestionCircleOutlined /></Popover></OverviewDataItemT>
                  {overviewStatus === OverviewStatusType.EMPTY ? <OverviewDataItemIcon><SyncOutlined/></OverviewDataItemIcon> : (overviewStatus === OverviewStatusType.LOADING ? <OverviewDataItemIcon><Loading3QuartersOutlined spin/></OverviewDataItemIcon>: <OverviewDataItemC>${price}</OverviewDataItemC>)}
                </OverviewDataItem>                
                <OverviewDataItem onClick={queryData}>
                  <OverviewDataItemT>Current Borrow Index <Popover placement='topLeft' content="Some description"><QuestionCircleOutlined /></Popover></OverviewDataItemT>          
                  {overviewStatus === OverviewStatusType.EMPTY ? <OverviewDataItemIcon><SyncOutlined/></OverviewDataItemIcon> : (overviewStatus === OverviewStatusType.LOADING ? <OverviewDataItemIcon><Loading3QuartersOutlined spin/></OverviewDataItemIcon>: <OverviewDataItemC>{index}</OverviewDataItemC>)}
                </OverviewDataItem>
                <OverviewDataItem onClick={queryData}>
                  <OverviewDataItemT>Current Borrow Rate <Popover placement='topLeft' content="Some description"><QuestionCircleOutlined /></Popover></OverviewDataItemT>
                  {overviewStatus === OverviewStatusType.EMPTY ? <OverviewDataItemIcon><SyncOutlined/></OverviewDataItemIcon> : (overviewStatus === OverviewStatusType.LOADING ? <OverviewDataItemIcon><Loading3QuartersOutlined spin/></OverviewDataItemIcon>: <OverviewDataItemC>{rate}%</OverviewDataItemC>)}
                </OverviewDataItem>
              </OverviewData>
            </BoxLayout>
          </OverviewZone>
        </div>

        <div>
          <OptZone>
            <BoxTitle>
              <BoxTitleContainer>
                <BoxTitleContent>Repay</BoxTitleContent>
              </BoxTitleContainer>
            </BoxTitle>
          
            <BoxLayout>
              <div>
                <FlexZone>
                  <FormLineTitle>Account</FormLineTitle>
                  <div style={{width:'100%', paddingLeft:'16px'}}><SelectComponent disable={connected ? false: true} device={props.device} onSelect={changeAccount} defaultValue={account} items={genAccountList()}/></div>
                </FlexZone>
              </div>
              <Space/>

              <div>
                <FlexZone>
                  <FormLineTitle>CDP</FormLineTitle>
                  <div style={{width:'100%', paddingLeft:'16px'}}><SelectComponent disable={connected ? false: true} device={props.device} onSelect={changeCDP} defaultValue={cdp} value={cdp} items={cdpList}/></div>
                </FlexZone>
              </div>
              <Space/>

              <FlexZone>
                <FlexZone>
                  <FormLineTitle>Repay</FormLineTitle>
                  <FormLineRight>
                      <div>
                        <NumericInput style={{ width: '', height: 38 }} onChange={changeInput} value={inputValue} disabled={connected && overviewStatus===OverviewStatusType.READY?  false:  true}/>
                      </div>
                      <div>
                        <SelectComponent height='32px' device={props.device} onSelect={()=>{}} defaultValue={borrowResourceAddress} value={borrowResourceAddress} items={CONFIG.BORROW_RESOURCE_LIST} disable={true}/>
                      </div>
                  </FormLineRight>
                </FlexZone>
              </FlexZone>

              {connected ? 
                <div style={{paddingLeft:'86px'}}>
                  <span style={{fontSize:'14px', color:'#003057'}}>{balance}</span>
                  <span style={{fontSize:'12px',color:'#8A8FA4'}}>(Available balance)</span>
                </div>
              :<></>}

              {repayAmount > 0 ? 
                <div style={{paddingLeft:'86px'}}>
                <span style={{fontSize:'14px', color:'#003057'}}>{repayAmount}</span>
                <span style={{fontSize:'12px',color:'#8A8FA4'}}>(To be repaid) <Button onClick={maxInputValue} size={'small'} type={'primary'}>max</Button></span>
              </div>
              :<></>
              }
              

              {isShowAlert? 
                <Alert style={{marginTop:'8px', marginBottom:'8px'}} message={alertMessage} type="error" />
              :<></>}

              <Space/>
            </BoxLayout>
          </OptZone>
          
          <SubmitWrapper>
            <SubmitButton status={submitButtonStatus} disabled={submitButtonStatus == SubmitButtonStatusType.DISABLE || submitButtonStatus == SubmitButtonStatusType.PROCESS ? true: false} onClick={doRepay}>
              {submitButtonStatus == SubmitButtonStatusType.READY || submitButtonStatus == SubmitButtonStatusType.DISABLE ? 'Repay':
              <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: '37px', color:'#FFF'}} spin/>}></Spin>
              }
            </SubmitButton>
          </SubmitWrapper>
        </div>
      </DataZone>
    </LayoutContainer>
  </Layout>);
}

// Layout
const Layout = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  display: flex;
`;
const LayoutContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  position: relative;
`;

// intro
const Intro = styled.div`
  box-sizing: border-box;
  margin: 80px 0px 24px;
  min-width: 0px;
  position: relative;
  justify-self: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  display: flex;
`
const IntroTitle = styled.div`
  box-sizing: border-box;
  margin: 0px 0px 16px;
  min-width: 0px;
  font-family: __FTPolarMedium_6973ac, __FTPolarMedium_Fallback_6973ac, "Helvetica Neue", sans-serif;
  font-weight: 500;
  line-height: 54px;
  font-size: 52px;
  letter-spacing: -0.02em;
  color: #25273D;
`
const IntroContent = styled.div`
  box-sizing: border-box;
  margin: 0px 0px 32px;
  min-width: 0px;
  font-family: Inter, "Helvetica Neue", sans-serif;
  line-height: 28px;
  font-size: 18px;
  color: #787A9B;
  max-width: 740px;
`

// Data zone
const DataZone = styled.div`
  gap: ${(props: {device: String}) => props.device ===  'mobile' ? '24px': '48px'};
  grid-template-columns: ${(props: {device: String}) => props.device === 'mobile' ? '2f4' : '2fr minmax(465px, 1fr)'};
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  align-items: flex-start;
`
const OverviewZone = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  border-radius: 20px;
  background-color: #FFFFFF;
  padding: 0px;
  border: 1px solid rgb(240, 240, 240);
  margin-top: 20px;
`
const BoxTitle = styled.div`
  box-sizing: border-box;
  margin: 0px 16px;
  min-width: 0px;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgb(240, 240, 240);
  display: flex;
`;
const BoxTitleContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  -webkit-box-align: center;
  align-items: center;
  height: 40px;
  display: flex;
`;
const BoxTitleContent = styled.p`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: Inter, "Helvetica Neue", sans-serif;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  color: #25273D;
`;
const BoxLayout = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 24px 16px;
`;
const OverviewData = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  gap: 16px;
  grid-template-columns: ${(props:{device:String}) => props.device === 'mobile' ? '1fr': '1fr 1fr'};
`;
const OverviewDataItem = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  border-radius: 8px;
  background-color: #FFFFFF;
  transition: background-color 200ms ease 0s;
  overflow-wrap: break-word;
  display: flex;
  cursor: pointer;
  :hover {
    background-color: #F3F7F9;
  }
`;
const OverviewDataItemT = styled.h3`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: Inter, "Helvetica Neue", sans-serif;
  font-weight: 600;
  line-height: 20px;
  font-size: 12px;
  color: #787A9B;
`;
const OverviewDataItemC = styled.p`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: __FTPolarMedium_6973ac, __FTPolarMedium_Fallback_6973ac, "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 32px;
  color: #25273D;
  max-width: 100%;
  line-height: 1.35;
`;
const OverviewDataItemIcon = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: __FTPolarMedium_6973ac, __FTPolarMedium_Fallback_6973ac, "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #8A8FA4;
  max-width: 100%;
  line-height: 1.35;
`
const OptZone = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  border-radius: 20px;
  background-color: #FFFFFF;
  position: relative;
  padding: 0px;
  border: 1px solid rgb(240, 240, 240);
  margin-top: 20px; 
`;

const FlexZone = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // margin-bottom: 8px;
`
const FormLineTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 80px;
  // padding-right: 16px;
  // margin-right: 16px;
  text-align: right;
`
const FormLineRight = styled.div`
  display: grid;
  width: 100%;
  padding-left: 16px;
  grid-template-columns: 2fr 1fr;
  gap: 8px;
`
// submit
const SubmitWrapper= styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  gap: 16px;
  padding: 24px;
  // border-top: 1px solid rgb(240, 240, 240);
`
// background-color: var(--theme-ui-colors-neutral60,#BEC9D0);
const SubmitButton= styled.button`
  // pointer-events: none;
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  appearance: none;
  text-align: center;
  text-decoration: none;
  padding: 8px 16px;
  background-color: ${(props:{status:SubmitButtonStatusType}) => props.status === SubmitButtonStatusType.DISABLE || props.status === SubmitButtonStatusType.PROCESS ? '#787A9B' : '#25273D'};
  border: 0px;
  font-family: Inter, "Helvetica Neue", sans-serif;
  line-height: 2.1em;
  font-size: 18px;
  color: #FFFFFF;
  cursor: pointer;
  font-weight: 600;
  border-radius: 32px;
  transition: background 200ms ease 0s;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  :hover {
    background-color: #787A9B;
  }
`
const Space = styled.div`
  height: 8px;
`

export default Repay;