import { put, takeEvery, call } from "redux-saga/effects";
import { WalletActionType } from "../reducers/Wallet";
import { RadixDappToolkit, WalletDataState } from "@radixdlt/radix-dapp-toolkit";
import { store } from '../store/configureStore';
import { GatewayStatusResponse,StateEntityDetailsVaultResponseItem } from "@radixdlt/radix-dapp-toolkit";

const enum PoolSagaActionType{
  QUERY_ASSET_DATAS="POOL_SAGA.QUERY_ASSET_DATAS"
}

export function* queryAssetDatas(action:any){
  const rdt:RadixDappToolkit = action.payload;

  // const rdt:RadixDappToolkit = store.getState().Wallet.rdt;
  // console.log(rdt.gatewayApi.status.getCurrent);
  // rdt.gatewayApi.status.getCurrent();

  // const ret:GatewayStatusResponse = yield call(rdt?.gatewayApi.status.getCurrent);
  // console.log(ret)
  // const ret:StateEntityDetailsVaultResponseItem = yield call(rdt.gatewayApi.state.getEntityDetailsVaultAggregated, '');
  // const result = rdt.gatewayApi.state.getEntityDetailsVaultAggregated(ASSET_COMPONENT_ADDRESS);
  // result.then(ret=>console.log(ret.details)).catch(err=>{console.log(err)})
}

export function* watchPool(){
  yield takeEvery("POOL_SAGA.QUERY_ASSET_DATAS", queryAssetDatas);
}

export {PoolSagaActionType};