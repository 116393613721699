import { useState } from 'react';
import styled from 'styled-components';
import { PoolData } from '../component/Types';
import { getUtilizationFactorByPoolData, numberToFactor, numberToPrice } from '../utils/ContractDataUtil';
import CONFIG from '../env/index'
import { useNavigate } from 'react-router-dom';
import { getDxTokenAddress } from '../utils/ContractDataUtil';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';


const PoolCard:React.FC<{device:String, data:PoolData, handleFaucet:Function}> = (props) => {
  
  const navigate = useNavigate();
  
  const [isSupplyRateModalOpen, setIsSupplyRateModalOpen] = useState(false);
  const [isBorrowRateModalOpen, setIsBorrowRateModalOpen] = useState(false);
  const [isTotalSupplyModalOpen, setIsTotalSupplyModalOpen] = useState(false);
  const [isAssetPriceModalOpen, setIsAssetPriceModalOpen] = useState(false);
  const [isUtilizationFactorModalOpen, setIsUtilizationFactorModalOpen] = useState(false);

  return(
    <PoolCardContainer>
      <PoolCardHeader>
        <div>
          {props.data.iconUrl ?
          <span><img style={{width:'24px', height:'24px'}} src={props.data.iconUrl} alt="icon"></img> </span>
          :<></>}
           {props.data.symbol}</div>
        {props.data.address !== CONFIG.XRD_ADDRESS ?
        // <FaucetButton onClick={()=>props.handleFaucet(props.data.address)}>
        //   faucet
        // </FaucetButton>
        <></>
        :<></>
        }
      </PoolCardHeader>
      <PoolCardContent>
        <PoolCardContentGrid device={props.device}>
          <PoolCardContentItemHover onClick={()=>{setIsSupplyRateModalOpen(true)}}>
            <PoolCardContentItemT>Supply Rate <QuestionCircleOutlined style={{marginLeft:'2px'}}/></PoolCardContentItemT>
            <PoolCardContentItemFactor>{numberToFactor(props.data.supplyInterestRate)}</PoolCardContentItemFactor>
          </PoolCardContentItemHover>
          <PoolCardContentItemHover onClick={()=>{setIsBorrowRateModalOpen(true)}}>
            <PoolCardContentItemT>Borrow Rate <QuestionCircleOutlined style={{marginLeft:'2px'}}/></PoolCardContentItemT>
            <PoolCardContentItemFactor>{numberToFactor(props.data.borrowInterestRate)}</PoolCardContentItemFactor>
          </PoolCardContentItemHover>
          <PoolCardContentItemHover onClick={()=>{setIsTotalSupplyModalOpen(true)}}>
            <PoolCardContentItemT>Total Supply <QuestionCircleOutlined style={{marginLeft:'2px'}}/></PoolCardContentItemT>
            <PoolCardContentItemC>{props.data.tokenTotalSupply.toFixed(8)}</PoolCardContentItemC>
          </PoolCardContentItemHover>
          <PoolCardContentItemHover onClick={()=>{setIsUtilizationFactorModalOpen(true)}}>
            <PoolCardContentItemT>Utilization factor <QuestionCircleOutlined style={{marginLeft:'2px'}}/></PoolCardContentItemT>
            <PoolCardContentItemFactor>{numberToFactor(getUtilizationFactorByPoolData(props.data))}</PoolCardContentItemFactor>
          </PoolCardContentItemHover>
          <PoolCardContentItemHover onClick={()=>{setIsAssetPriceModalOpen(true)}}>
            <PoolCardContentItemT>Assset Price<QuestionCircleOutlined style={{marginLeft:'2px'}}/> </PoolCardContentItemT>
            <PoolCardContentItemC>{numberToPrice(props.data.price, 6)}</PoolCardContentItemC>
          </PoolCardContentItemHover>
        </PoolCardContentGrid>
        
      </PoolCardContent>
      <SubmitButtonArea>
          <SubmitButton onClick={()=>{navigate('/supply/'+props.data.address)}}>Supply</SubmitButton>
          <SubmitButton onClick={()=>{navigate('/withdraw/'+getDxTokenAddress(props.data.address))}}>Withdraw</SubmitButton>
        </SubmitButtonArea>

      <Modal title="Supply Rate" 
        maskClosable={true} 
        open={isSupplyRateModalOpen}
        onCancel={()=>{setIsSupplyRateModalOpen(false)}}
        footer={null}>
        <p>The current supply annual interest rate</p>
      </Modal>

      <Modal title="Borrow Rate" 
        maskClosable={true} 
        open={isBorrowRateModalOpen}
        onCancel={()=>{setIsBorrowRateModalOpen(false)}}
        footer={null}>
        <p>The current borrow annual interest rate</p>
      </Modal>

      <Modal title="Total Supply" 
        maskClosable={true} 
        open={isTotalSupplyModalOpen}
        onCancel={()=>{setIsTotalSupplyModalOpen(false)}}
        footer={null}>
        <p>Current balance of total assets in this pool</p>
      </Modal>

      <Modal title="Asset Price" 
        maskClosable={true} 
        open={isAssetPriceModalOpen}
        onCancel={()=>{setIsAssetPriceModalOpen(false)}}
        footer={null}>
        <p>The current price of the asset in USDT, a price that currently comes from oracle</p>
      </Modal>

      <Modal title="Utilization factor" 
        maskClosable={true} 
        open={isUtilizationFactorModalOpen}
        onCancel={()=>{setIsUtilizationFactorModalOpen(false)}}
        footer={null}>
        <p>Average borrowing rate</p>
      </Modal>

      
    </PoolCardContainer>
  )
}

const PoolCardContainer = styled.div`
  box-sizing: border-box;
  margin: 20px 0px 0px 0px;
  min-width: 0px;
  border-radius: 20px;
  background-color: #FFFFFF;
  padding: 0px;
  border: 1px solid rgb(240, 240, 240);
  :hover {
    border: 1px solid #5B978E;
  }
`

const PoolCardHeader = styled.div`
  display: flex;
  box-sizing: border-box;
  margin: 0px 16px;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(240, 240, 240);
`

const PoolCardContent = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  margin: 0 16px;
  // border-bottom: 1px solid rgb(240, 240, 240);
`

const PoolCardContentGrid = styled.div`
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  grid-template-columns: ${(props:{device:String}) => props.device === 'mobile' ? '1fr': '1fr 1fr'};
`

const PoolCardContentItemHover = styled.div`
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  cursor: pointer;
  align-items: flex-start;
  overflow-wrap: break-word;
  border-radius: 8px;
  :hover {
    background-color: #F3F7F9;
  }
`

const PoolCardContentItemT = styled.p`
  box-sizing: border-box;
  font-family: Inter, "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 11px;
  color: #787A9B;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
`

const PoolCardContentItemC = styled.span`
  box-sizing: border-box;
  // margin: 0px 8px;
  min-width: 0px;
  font-weight: 400;
  font-size: 16px;
  color: #25273D;
  max-width: 100%;
  line-height: 1.35;
`;

const PoolCardContentItemFactor = styled.span`
  box-sizing: border-box;
  // margin: 0px 8px;
  min-width: 0px;
  font-weight: 400;
  font-size: 18px;
  color: #5B978E;
  max-width: 100%;
  line-height: 1.35;
`

const FaucetButton = styled.button`
  background-color: 91, 151, 142; 
  border-radius: 8px;
  font-weight: 400;
  border: none;
  color: #5B978E;
  cursor: pointer;
`

const SubmitButtonArea = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
`

const SubmitButton= styled.button`
  box-sizing: border-box;
  margin: 0px 6px;
  min-width: 0px;
  appearance: none;
  text-align: center;
  text-decoration: none;
  padding: 8px 16px;
  background-color: #25273D;
  border: 0px;
  font-family: Inter, "Helvetica Neue", sans-serif;
  line-height: 2.1em;
  font-size: 12px;
  color: #FFFFFF;
  cursor: pointer;
  font-weight: 600;
  border-radius: 32px;
  transition: background 200ms ease 0s;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  :hover {
    background-color: #787A9B;
  }
`

export default PoolCard;